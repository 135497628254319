<template>
  <div class="list-margin">
    <DataTable
      :value="foodpasses"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="userid"
      :rowHover="true"
      :resizableColumns="true"
      :stickyHeader="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          :currentPageReportTemplate="
        $t('showing') +
        ' ' +
        `{first}` +
        ' ' +
        $t('to') +
        ' ' +
        `{last}` +
        ' ' +
        $t('of') +
        ' ' +
        `{totalRecords}` +
        ' ' +
        $t('results')
      "
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">{{$t('foodpassreport')}}</div>
          <div class="col-6 text-right ml-4">
            <div class="p-input-icon-right">
              <i
                class="search-icon cursor-pointer"
                id="search"
                @click="onSearch"
              />
              <InputText
                v-model="search"
                :placeholder="$t('search')"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="orderid" :header="$t('order')" sortable="true"></Column>
      <Column field="createddate" :header="$t('orderdate')" sortable="true"></Column>
      <Column field="username" :header="$t('username')" sortable="true"></Column>
      <Column field="phoneno" :header="$t('phone')" sortable="true"></Column>
      <Column field="foodpassname" :header="$t('foodpass')" sortable="true"></Column>
      <Column field="totalqty" :header="$t('mealqty')" sortable="true"></Column>
      <Column
        field="remainingqty"
        :header="$t('remainingqty')"
        sortable="true"
      ></Column>
      <Column field="redeemqty" :header="$t('redeemedqty')" sortable="true"></Column>
      <Column field="price" :header="$t('price')" sortable="true"></Column>
      <Column
        field="paymentmethod"
        :header="$t('paymentmethod')"
        sortable="true"
      ></Column>
      <Column field="expirydate" :header="$t('expirydate')" sortable="true"></Column>
    </DataTable>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import FoodPassFactory from "@/factories/foodpassreport.js";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      foodpasses: [],
    };
  },

  mounted() {
    this.getFoodPassList();
  },
  methods: {
    getFoodPassList() {
      this.foodpasses = [];
      let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/foodpasstouser/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${encoded}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.foodpasses = FoodPassFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getFoodPassList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getFoodPassList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getFoodPassList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>
