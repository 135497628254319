<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div v-if="$route.params.id" class="flex order-form-title">{{$t('pickuptime')}} / {{ $t('edit') }}</div>
        <div v-else class="flex order-form-title">{{$t('pickuptime')}} / {{ $t('add') }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">{{$t('pickuptimedetail')}}</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-12">
                  <InputTextRequire
                    v-model="vmodel.pickuptime"
                    :validation="v$.vmodel.pickuptime"
                    sectionClass="field mt-1"
                    id="pickuptime"
                    :label="$t('pickuptime')"
                    class="disable-text-field"
                    :placeholder="$t('pickuptime')"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  :label="$t('add')"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createPickUpTime"
                ></PrimeButton>
                <PrimeButton
                  :label="$t('edit')"
                  class="order-form-button"
                  v-else
                  @click="createPickUpTime"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      vmodel: {
        pickuptime: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        pickuptime: { required, max: maxLength(15) },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getPickUpTimeById(this.$route.params.id);
    }
  },
  methods: {
    async createPickUpTime() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          pickuptime: this.vmodel.pickuptime,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/pickupdatetime", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PickupTime" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/pickupdatetime", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PickupTime" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getPickUpTimeById() {
      APIService.getById("/pickupdatetime", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.pickuptime = response.data.data.pickuptime;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "PickupTime" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.order-form-sub-title{
  text-transform: none !important;
}
.order-form-title{
  text-transform: none !important;
}
</style>