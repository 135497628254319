<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div v-if="$route.params.id" class="flex order-form-title">{{$t('promotioncode')}} / {{ $t('edit') }}</div>
        <div v-else class="flex order-form-title">{{$t('promotioncode')}} / {{ $t('add') }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">{{$t('promotioncodedetail')}}</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    :label="$t('promotioncode')"
                    v-model="vmodel.code"
                    :validation="v$.vmodel.code"
                    :placeholder="$t('promotioncode')"
                    sectionClass="field mt-1"
                    id="code"
                    maxlength="500"
                  />
                </div>
              </div>
              <TextAreaRequire
                v-model="vmodel.description"
                :validation="v$.vmodel.description"
                :placeholder="$t('description')"
                sectionClass="field mt-1"
                id="description"
                :label="$t('description')"
                maxlength="500"
              />
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.type"
                    :validation="v$.vmodel.type"
                    sectionClass="field mt-1"
                    id="type"
                    :options="typeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :label="$t('type')"
                    @update:modelValue="setDiscount()"
                  />
                </div>
                <div class="col-6">
                  <InputGroup
                    v-model="vmodel.discount"
                    :validation="v$.vmodel.discount"
                    :placeholder="$t('price')"
                    sectionClass="field mt-1"
                    id="discount"
                    :label="$t('discount')"
                    :min="1"
                    v-if="vmodel.type == 'fixed discount'"
                  />
                  <InputPercent
                    v-model="vmodel.discount"
                    :validation="v$.vmodel.discount"
                    :placeholder="$t('discount')"
                    sectionClass="field mt-1"
                    id="discount"
                    :label="$t('discount')"
                    :min="1"
                    :max="100"
                    v-else
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  :label="$t('add')"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createPromotionCode"
                ></PrimeButton>
                <PrimeButton
                  :label="$t('edit')"
                  class="order-form-button"
                  v-else
                  @click="createPromotionCode"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api-service.js";
import {
  required,
  maxLength,
  minLength,
  minValue,
  maxValue,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isEditMode: false,
      discountprice: 100,
      vmodel: {
        code: "",
        description: "",
        type: "percentage discount",
        discount: 1,
      },
      submitted: false,
      typeOptions: [
        { label: "Percentage Discount", value: "percentage discount" },
        { label: "Fixed Discount", value: "fixed discount" },
      ],
    };
  },
  validations() {
    return {
      vmodel: {
        code: { required, min: minLength(3), max: maxLength(15) },
        description: { required, max: maxLength(250) },
        type: { required },
        discount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.discountprice),
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getPromocodeById(this.$route.params.id);
    }
  },
  methods: {
    async createPromotionCode() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        if (this.vmodel.type == "percentage discount") {
          if (this.vmodel.discount > 100) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: " Discount should not allow than 100%",
              life: 3000,
            });
            return;
          }
        }
        let payload = {
          code: this.vmodel.code,
          description: this.vmodel.description,
          type: this.vmodel.type,
          discount: this.vmodel.discount,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/promotioncode", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PromotionCode" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/promotioncode", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PromotionCode" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getPromocodeById() {
      APIService.getById("/promotioncode/", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.code = response.data.data.code;
            this.vmodel.description = response.data.data.description;
            this.vmodel.type = response.data.data.type;
            this.vmodel.discount = response.data.data.discount;
            this.setDiscountLimit();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "PromotionCode" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    setDiscount() {
      this.vmodel.discount = 1;
      this.setDiscountLimit();
    },
    setDiscountLimit() {
      if (this.vmodel.type == "percentage discount") {
        this.discountprice = 100;
      } else {
        this.discountprice = 99999999;
      }
    },
  },
};
</script>
