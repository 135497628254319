<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div v-if="$route.params.id" class="flex order-form-title">{{$t('membermanagement')}} / {{ $t('edit') }}</div>
      <div v-else class="flex order-form-title">{{$t('membermanagement')}} / {{ $t('add') }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">{{$t('memberdetail')}}</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.userid"
                    :validation="v$.vmodel.userid"
                    sectionClass="field mt-1"
                    id="userid"
                    :label="$t('useridautogenerate')"
                    class="disable-text-field"
                    :placeholder="$t('userid')"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-1">
                  <label for="span" class="form-label">{{$t('avatar')}}</label>
                  <span id="span">
                    <img
                      src="../../assets/images/avatar.png"
                      alt=""
                      class="avatar-img mt-1"
                      v-if="avatar == 'avatar.png'"
                    />
                    <img
                      :src="vmodel.avatar"
                      alt=""
                      class="avatar-img mt-1"
                      v-else
                    />
                  </span>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept=".png, .jpg, .jpeg"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
                <div class="col-3 ml-5 flex">
                  <PrimeButton
                    :label="$t('upload')"
                    class="upload-button"
                    @click="openFileUpload"
                  />
                  <PrimeButton
                    :label="$t('remove')"
                    class="remove-button ml-3"
                    @click="onCancel"
                    v-if="avatar != 'avatar.png'"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.username"
                    :validation="v$.vmodel.username"
                    sectionClass="field mt-1"
                    id="username"
                    :label="$t('username')"
                    :placeholder="$t('username')"
                    maxlength="50"
                  />
                </div>
                <div class="col-6">
                  <InputDate
                    v-model="vmodel.birthdate"
                    :validation="v$.vmodel.birthdate"
                    sectionClass="field mt-1"
                    class="birthdate"
                    :manualInput="false"
                    id="birthdate"
                    :label="$t('birthday')"
                    :placeholder="$t('dateformat')"
                    :maxDate="birthdayMaxDate"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.email"
                    :validation="v$.vmodel.email"
                    sectionClass="field mt-1"
                    id="email"
                    :label="$t('email')"
                    :placeholder="$t('email')"
                    maxlength="50"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.phoneno"
                    :validation="v$.vmodel.phoneno"
                    sectionClass="field mt-1"
                    id="phoneno"
                    :label="$t('phone')"
                    :placeholder="$t('phone')"
                    maxlength="8"
                    minlength="8"
                    @keypress="onlyNumber"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <label for="Qty" class="form-label">{{$t('stampquantity')}}</label>
                  <div id="Qty" class="quantity-field mt-2">
                    <PrimeButton
                      class="value-button decrease-button"
                      @click="decrement()"
                      :class="{
                        'p-invalid': v$.vmodel.stampquantity.$error,
                      }"
                    >
                      <span class="decrement-icon"></span>
                    </PrimeButton>
                    <InputNumber
                      v-model="vmodel.stampquantity"
                      :validation="v$.vmodel.stampquantity"
                      :class="{
                        'p-invalid': v$.vmodel.stampquantity.$error,
                      }"
                      class="number"
                    />
                    <PrimeButton
                      class="value-button increase-button"
                      @click="increment"
                      :class="{
                        'p-invalid': v$.vmodel.stampquantity.$error,
                      }"
                    >
                      <span class="increment-icon"></span>
                    </PrimeButton>
                  </div>
                  <span
                    v-if="
                      v$.vmodel.stampquantity.$error &&
                      v$.vmodel.stampquantity.required.$invalid
                    "
                    id="stampquantity-help"
                    class="p-error text-left mt-2"
                  >
                    {{
                      v$.vmodel.stampquantity.required.$message.replace(
                        "Value",
                        "Stamp Quantity"
                      )
                    }}
                  </span>
                  <span
                    v-else-if="
                      v$.vmodel.stampquantity.$error &&
                      v$.vmodel.stampquantity.minValue.$invalid
                    "
                    id="stampquantity-help"
                    class="p-error text-left mt-2"
                  >
                    {{
                      v$.vmodel.stampquantity.minValue.$message.replace(
                        "Value",
                        "Stamp Quantity"
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  :label="$t('add')"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  :label="$t('edit')"
                  class="order-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  numeric,
  minValue,
} from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      avatar: "avatar.png",
      birthdayMaxDate: new Date(),
      vmodel: {
        userid: "",
        avatar: "avatar.png",
        username: "",
        birthdate: null,
        email: "",
        phoneno: "",
        stampquantity: 0,
      },
    };
  },
  validations() {
    return {
      vmodel: {
        userid: {},
        avatar: { required },
        username: { required, max: maxLength(50) },
        birthdate: {},
        email: { required, email, max: maxLength(50) },
        phoneno: { min: minLength(8), max: maxLength(8), numeric },
        stampquantity: { required, minValue: minValue(0) },
      },
    };
  },
  created() {
    let today = new Date();
    this.birthdayMaxDate.setDate(today.getDate() - 1);
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getMemberById();
    } else {
      this.vmodel.userid = Math.floor(
        1000000000 + Math.random() * 90000000
      ).toString();
    }
  },

  methods: {
    increment() {
      this.vmodel.stampquantity = parseInt(this.vmodel.stampquantity) + 1;
    },
    decrement() {
      this.vmodel.stampquantity = parseInt(this.vmodel.stampquantity) - 1;
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    onCancel() {
      this.vmodel.avatar = "avatar.png";
      this.avatar = "avatar.png";
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          userid: this.vmodel.userid,
          avatar: this.avatar,
          username: this.vmodel.username,
          birthdate: this.vmodel.birthdate,
          email: this.vmodel.email,
          phoneno: this.vmodel.phoneno,
          stampquantity: this.vmodel.stampquantity,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/member", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/member", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "MemberManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getMemberById() {
      this.avatar = "";
      APIService.getById("/member", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.avatar = response.data.data.avatar;
            this.vmodel.avatar = response.data.data.avatarpath;
            this.vmodel.username = response.data.data.username;
            this.vmodel.birthdate = DateFormat.formatDate(
              response.data.data.birthdate
            );
            this.vmodel.email = response.data.data.email;
            this.vmodel.phoneno = response.data.data.phoneno;
            this.vmodel.stampquantity = response.data.data.stampquantity;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid avatar",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Avatar size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/member/avatar", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.avatar = response.data.data.name;
              this.vmodel.avatar = response.data.data.path;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quantity-field {
  display: flex;
  align-items: center;
  height: 56px;
  width: 192px;
  border-radius: 4px !important;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 52px;
  padding: 0;
  background: #ffffff;
  outline: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;
}

.quantity-field .value-button:hover {
  background: #ffffff;
}

.quantity-field .value-button:active {
  background: #ffffff;
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-width: 1px 1px 1px 1px;
  // border-radius: 8px 0 0 8px;
  // border-right: 1px solid #e6e3e1 !important;
  width: 56px;
  height: 56px;
  border-radius: 0px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-width: 1px 1px 1px 1px;
  width: 56px;
  height: 56px;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

::v-deep(.quantity-field .number) {
  .p-inputtext {
    display: flex;
    text-align: center;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    // margin: 0px;
    width: 80px;
    height: 56px;
    box-sizing: border-box;
    background: white;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    // padding-top: 15px;
    // padding-right: 5px;
    // padding-left: 1px;
    color: #141414;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
}
.avatar-img {
  border: 1px solid #d4d7d8;
  border-radius: 80px;
  height: 80px;
  width: 80px;
}
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: #e5e3e1;
    border: 1px solid #cccac8;
  }
}
::v-deep(.p-button.p-invalid) {
  border-color: #dc3545;
}
</style>
