import DateFormat from "@/libs/DateFormat"

export default class ItemManagement {
    constructor(data = {}) {
        return {
            id: data.id || '',
            image: data.image || '',
            itemname: data.itemname || '',
            category: data.category || '',
            categoryname: data.categoryname || '',
            detail: data.detail || '',
            qty: data.qty || 0,
            price: data.price || 0,
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}