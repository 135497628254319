/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import store from "./store";

import InputText from "primevue/inputtext";
import Textarea from 'primevue/textarea';
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Toast from "primevue/toast";
import Chip from "primevue/chip";
import ToastService from "primevue/toastservice";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ConfirmPopup from "@/components/modal-popup/confirm-popup.vue";
import ConfirmationService from "primevue/confirmationservice";
import InputTextRequire from "@/components/form-control/input-text.vue";
import TextAreaRequire from '@/components/form-control/text-area.vue';
import InputTextCustom from "@/components/form-control/text-input.vue";
import InputDate from "@/components/form-control/input-date.vue";
import InputNumberRequire from "@/components/form-control/input-number.vue";
import InputGroupPercentage from "@/components/form-control/input-group-percentage.vue";
import InputGroup from "@/components/form-control/input-group.vue";
import InputGroupCustom from "@/components/form-control/group-input.vue";
import Dropdown from "primevue/dropdown";
import DropDownRequire from "@/components/form-control/drop-down.vue";
import DropDownCustom from "@/components/form-control/input-drop-down.vue";
import moment from "moment-timezone";
import Calendar from "primevue/calendar";
import FileUpload from "primevue/fileupload";
import Badge from "primevue/badge";
import Card from "primevue/card";
import CascadeSelect from 'primevue/cascadeselect';
import RadioButton from 'primevue/radiobutton';

import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "@/assets/css/global.css";
import i18n from './i18n'

import Editor from "primevue/editor";
import EditorRequire from "@/components/form-control/editor.vue";
import clickOutside from "@/components/directive/useDetectOutsideClick";

const app = createApp(App).use(i18n).use(i18n);
app.use(ToastService);
app.use(ConfirmationService);
app.use(PrimeVue);
app.use(store);
app.use(moment);

app.directive("click-outside", clickOutside);

app.component("InputText", InputText);
app.component('Textarea', Textarea);
app.component("InputNumber", InputNumber);
app.component("PrimeButton", Button);
app.component("PrimeToast", Toast);
app.component("ToastService", ToastService);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Chip", Chip);
app.component("FileUpload", FileUpload);
app.component("ConfirmPopup", ConfirmPopup);
app.component("InputTextRequire", InputTextRequire);
app.component('TextAreaRequire', TextAreaRequire)
app.component("InputTextCustom", InputTextCustom);
app.component("InputNumberRequire", InputNumberRequire);
app.component("InputPercent", InputGroupPercentage);
app.component("InputDate", InputDate);
app.component("Calendar", Calendar);
app.component("InputGroup", InputGroup);
app.component("InputGroupCustom", InputGroupCustom);
app.component("Dropdown", Dropdown);
app.component("DropDownRequire", DropDownRequire);
app.component("DropDownCustom", DropDownCustom);
app.component("Badge", Badge);
app.component("Card", Card);
app.component("CascadeSelect", CascadeSelect);
app.component("RadioButton", RadioButton);

app.component("Editor", Editor);
app.component("EditorRequire", EditorRequire);

app.use(router).mount("#app");
