<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-top">
    <span class="navbar-text">{{$t("luckyinternational")}}</span>
    <div
      class="cursor-pointer profile-box"
      :class="visibleDDMenu === true ? 'profile-selected' : ''"
      @click="visibleDDMenu = !visibleDDMenu"
      v-click-outside="hide"
    >
      <span class="navbar-profile-text">
        {{ $store.state.userInfo.username }}
      </span>
      <img src="../../assets/images/avatar.png" class="img-style" />
    </div>
  </div>
  <div v-if="visibleDDMenu" class="dropdown">
    <div class="grid">
      <div
        style="margin-top: 8px"
        class="col-12 ml-2 profile-setting-text cursor-pointer"
        @click="goToProfilePage"
      >
        {{$t("profilesetting")}}
      </div>
    </div>
    <div class="grid">
      <hr class="line" />
    </div>
    <div
        class="col-12 profile-setting-text cursor-pointer"
        @click="language"
      >
        {{$t("languagesetting")}}
      </div>
      <div class="grid">
         <hr class="line" />
      <div class="">
      <div
        class="col-12 ml-2 profile-setting-text cursor-pointer"
        @click="logout"
      >
        {{$t("logout")}}
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleDDMenu: false,
    };
  },
  methods: {
    hide() {
      this.visibleDDMenu = false;
    },
    goToProfilePage() {
      this.$router.push({ name: "ProfileSetting" });
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
    language(){
       this.$router.push({ name: "LanguageSetting" });
    }
  },
};
</script>

<style scoped>
.img-style {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  position: fixed;
  width: 160px;
  height: 195px;
  right: 2.5rem;
  top: 92px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(187, 187, 187, 0.4);
  border-radius: 4px;
  z-index: 9999;
}

.profile-setting-text {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 160px;
  height: 56px;
  gap: 8px;
  padding: 16px 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.profile-setting-text:hover {
  background: #f4f5f6;
}

.line {
  margin-left: 7px;
  width: 160px;
  border-bottom: 1px solid #cccac8;
  /* margin-top: 24px; */
}

.logout {
  margin-top: 10px;
  margin-left: 6px;
}
.profile-selected {
  background: #f4f5f6;
}
</style>
