export default {
  "luckyinternational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky Up International Limited"])},
  "membermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Management"])},
  "itemcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Category"])},
  "subitemcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Item Category"])},
  "itemmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Management"])},
  "subitemmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Item Management"])},
  "promotioncode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Code"])},
  "pickuptime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up Time"])},
  "foodpassmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Pass Management"])},
  "foodpassreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Pass Report"])},
  "ordermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Management"])},
  "cmspagemanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS Page Management"])},
  "privacypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "termsandcondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Condition"])},
  "profilesetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Setting"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "languagesetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language Setting"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
  "forgotpasswordtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot assword"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "resetpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
  "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
  "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
  "remberit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember it?"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "userid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "stampquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamp Quantity"])},
  "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created At"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "memberdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member Detail"])},
  "useridautogenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID (Auto Generate)"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday"])},
  "itemcategorydetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Category Detail"])},
  "subitemcategorydetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Item Category Detail"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
  "itemdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Detail"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "itemname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Name"])},
  "selectcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Category"])},
  "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
  "subitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Item"])},
  "subitemdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Item Detail"])},
  "selectsubitemcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Sub Item Category"])},
  "subitemname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Item Name"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
  "promotioncodedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion Code Detail"])},
  "percentagediscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage Discount"])},
  "fixeddiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Discount"])},
  "pickuptimedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up Time Detail"])},
  "foodpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Pass"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "mealqty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal Qty"])},
  "expirydate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiry Date"])},
  "foodpassdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Pass Detail"])},
  "foodpassname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Pass Name"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order #"])},
  "orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Date"])},
  "remainingqty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Qty"])},
  "redeemedqty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redeemed Qty"])},
  "paymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Method"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "pickupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up Date"])},
  "totalprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Price"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "orderdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Detail"])},
  "itemlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item List"])},
  "addnewitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD NEW ITEM"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])},
  "ordertotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Total"])},
  "privacypolicydetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy Page Detail"])},
  "termsandconditionsdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Conditions Page Detail"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "dateformat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY-MM-DD"])},
  "loadingrecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading records, please wait..."])},
  "norecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found."])},
  "selectitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Item"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qty"])},
  "ordersummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Summary"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese"])},
  "deleteconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Confirmation"])},
  "doyouwantdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this record ?"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["results"])}
}