import { createWebHistory, createRouter } from "vue-router";
import Store from "@/store/index";
import MainLayout from "@/views/main-layout.vue";

import Login from "@/views/login.vue";
import ForgotPassword from "@/views/forgot-password.vue";
import ResetPassword from "@/views/reset-password.vue";
import MemberManagement from "@/views/MemberManagement/member-list.vue";
import MemberManagementCreate from "@/views/MemberManagement/member-form.vue";
import ItemCategory from "@/views/ItemCategory/itemcategory-list.vue";
import ItemCategoryCreate from "@/views/ItemCategory/itemcategory-form.vue";
import SubItemCategory from "@/views/SubItemCategory/subitemcategory-list.vue";
import SubItemCategoryCreate from "@/views/SubItemCategory/subitemcategory-form.vue";
import ItemManagement from "@/views/ItemManagement/itemmanagement-list.vue";
import ItemManagementCreate from "@/views/ItemManagement/itemmanagement-form.vue";
import SubItemManagement from "@/views/SubItemManagement/subitemmanagement-list.vue";
import SubItemManagementCreate from "@/views/SubItemManagement/subitemmanagement-form.vue";
import PromotionCode from "@/views/PromotionCode/promotioncode-list.vue";
import PromotionCodeCreate from "@/views/PromotionCode/promotioncode-form.vue";
import PickupTime from "@/views/PickUpTime/pickuptime-list.vue";
import PickupTimeCreate from "@/views/PickUpTime/pickuptime-form.vue";
import FoodpassManagement from "@/views/FoodPassManagement/foodpassmanagement-list.vue";
import FoodpassManagementCreate from "@/views/FoodPassManagement/foodpassmanagement-form.vue";
import FoodpassReport from "@/views/FoodPassReport/foodpassreport-list.vue";
import OrderManagement from "@/views/OrderManagement/ordermanagement-list.vue";
import OrderManagementCreate from "@/views/OrderManagement/ordermanagement-form.vue";
import PrivacyPolicy from "@/views/PrivacyPolicyPage/privacy-policy.vue";
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue";
import ProfileSetting from "@/views/ProfileSetting/profile-setting.vue";
import LanguageSetting from "@/views/LanguageSetting/LanguageSetting.vue"
import OrderManagementView from "@/views/OrderManagement/order-management-view.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Forgot password",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Reset Password",
    },
  },
  {
    component: MainLayout,
    children: [
      {
        path: "/member-management",
        name: "MemberManagement",
        component: MemberManagement,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member management",
        },
      },
      {
        path: "/member-management/create",
        name: "MemberManagementCreate",
        component: MemberManagementCreate,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management > Add",
        },
      },
      {
        path: "/member-management/edit/:id",
        name: "MemberManagementEdit",
        component: MemberManagementCreate,
        meta: {
          requiresAuth: true,
          module: "MemberManagement",
          title: "Member Management > Edit",
        },
      },
      {
        path: "/item-category",
        name: "ItemCategory",
        component: ItemCategory,
        meta: {
          requiresAuth: true,
          module: "ItemCategory",
          title: "Item Category",
        },
      },
      {
        path: "/item-category/create",
        name: "ItemCategoryCreate",
        component: ItemCategoryCreate,
        meta: {
          requiresAuth: true,
          module: "ItemCategory",
          title: "Item Category > Add",
        },
      },
      {
        path: "/item-category/edit/:id",
        name: "ItemCategoryEdit",
        component: ItemCategoryCreate,
        meta: {
          requiresAuth: true,
          module: "ItemCategory",
          title: "Item Category > Edit",
        },
      },
      {
        path: "/subitem-category",
        name: "SubItemCategory",
        component: SubItemCategory,
        meta: {
          requiresAuth: true,
          module: "SubItemCategory",
          title: "Sub Item Category",
        },
      },
      {
        path: "/subitem-category/create",
        name: "SubItemCategoryCreate",
        component: SubItemCategoryCreate,
        meta: {
          requiresAuth: true,
          module: "SubItemCategory",
          title: "Sub Item Category > Add",
        },
      },
      {
        path: "/subitem-category/edit/:id",
        name: "SubItemCategoryEdit",
        component: SubItemCategoryCreate,
        meta: {
          requiresAuth: true,
          module: "SubItemCategory",
          title: "Sub Item Category > Edit",
        },
      },
      {
        path: "/item-management",
        name: "ItemManagement",
        component: ItemManagement,
        meta: {
          requiresAuth: true,
          module: "ItemManagement",
          title: "Item management",
        },
      },
      {
        path: "/item-management/create",
        name: "ItemManagementCreate",
        component: ItemManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ItemManagement",
          title: "Item management > Add",
        },
      },
      {
        path: "/item-management/edit/:id",
        name: "ItemManagementEdit",
        component: ItemManagementCreate,
        meta: {
          requiresAuth: true,
          module: "ItemManagement",
          title: "Item management > Edit",
        },
      },
      {
        path: "/subitem-management",
        name: "SubItemManagement",
        component: SubItemManagement,
        meta: {
          requiresAuth: true,
          module: "SubItemManagement",
          title: "Sub Item management",
        },
      },
      {
        path: "/subitem-management/create",
        name: "SubItemManagementCreate",
        component: SubItemManagementCreate,
        meta: {
          requiresAuth: true,
          module: "SubItemManagement",
          title: "Sub Item management > Add",
        },
      },
      {
        path: "/subitem-management/edit/:id",
        name: "SubItemManagementEdit",
        component: SubItemManagementCreate,
        meta: {
          requiresAuth: true,
          module: "SubItemManagement",
          title: "Sub Item management > Edit",
        },
      },
      {
        path: "/promotion-code",
        name: "PromotionCode",
        component: PromotionCode,
        meta: {
          requiresAuth: true,
          module: "PromotionCode",
          title: "Promotion Code",
        },
      },
      {
        path: "/promotion-code/create",
        name: "PromotionCodeCreate",
        component: PromotionCodeCreate,
        meta: {
          requiresAuth: true,
          module: "PromotionCode",
          title: "Promotion Code / Add",
        },
      },
      {
        path: "/promotion-code/edit/:id",
        name: "PromotionCodeEdit",
        component: PromotionCodeCreate,
        meta: {
          requiresAuth: true,
          module: "PromotionCode",
          title: "Promotion Code > Edit",
        },
      },

      {
        path: "/pickup-time",
        name: "PickupTime",
        component: PickupTime,
        meta: {
          requiresAuth: true,
          module: "PickupTime",
          title: "Pickup Time",
        },
      },
      {
        path: "/pickup-time/create",
        name: "PickupTimeCreate",
        component: PickupTimeCreate,
        meta: {
          requiresAuth: true,
          module: "PickupTime",
          title: "Pickup Time > Add",
        },
      },
      {
        path: "/pickup-time/edit/:id",
        name: "PickupTimeEdit",
        component: PickupTimeCreate,
        meta: {
          requiresAuth: true,
          module: "PickupTime",
          title: "Pickup Time > Edit",
        },
      },
      {
        path: "/foodpass-management",
        name: "FoodpassManagement",
        component: FoodpassManagement,
        meta: {
          requiresAuth: true,
          module: "FoodpassManagement",
          title: "Foodpass Management",
        },
      },
      {
        path: "/foodpass-management/create",
        name: "FoodpassManagementCreate",
        component: FoodpassManagementCreate,
        meta: {
          requiresAuth: true,
          module: "FoodpassManagement",
          title: "Foodpass Management > Add",
        },
      },
      {
        path: "/foodpass-management/edit/:id",
        name: "FoodpassManagementEdit",
        component: FoodpassManagementCreate,
        meta: {
          requiresAuth: true,
          module: "FoodpassManagement",
          title: "Foodpass Management > Edit",
        },
      },
      {
        path: "/foodpass-report",
        name: "FoodpassReport",
        component: FoodpassReport,
        meta: {
          requiresAuth: true,
          module: "FoodpassReport",
          title: "Foodpass Report",
        },
      },
      {
        path: "/order-management",
        name: "OrderManagement",
        component: OrderManagement,
        meta: {
          requiresAuth: true,
          module: "OrderManagement",
          title: "Order management",
        },
      },
      {
        path: "/order-management/create",
        name: "OrderManagementCreate",
        component: OrderManagementCreate,
        meta: {
          requiresAuth: true,
          module: "OrderManagement",
          title: "Order management > Add",
        },
      },
      {
        path: "/order-management/edit/:id",
        name: "OrderManagementEdit",
        component: OrderManagementCreate,
        meta: {
          requiresAuth: true,
          module: "OrderManagement",
          title: "Order management > Edit",
        },
      },
      {
        path: "/order-management/view/:id",
        name: "OrderManagementView",
        component: OrderManagementView,
        meta: {
          requiresAuth: true,
          module: "OrderManagement",
          title: "Order management > View",
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
          requiresAuth: true,
          module: "PrivacyPolicy",
          title: "Privacy policy",
        },
      },
      {
        path: "/terms-conditions",
        name: "TermsAndConditions",
        component: TermsAndConditions,
        meta: {
          requiresAuth: true,
          module: "TermsAndConditions",
          title: "Terms and conditions",
        },
      },
      {
        path: "/profile-setting",
        name: "ProfileSetting",
        component: ProfileSetting,
        meta: {
          requiresAuth: true,
          module: "ProfileSetting",
          title: "Profile Setting",
        },
      },
      {
        path: "/language-setting",
        name: "LanguageSetting",
        component: LanguageSetting,
        meta: {
          requiresAuth: true,
          module: "LanguageSetting",
          title: "Language Setting",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
// router.beforeEach((to) => {
//   Store.commit("setActiveRoute", to.meta.module);
// });
router.beforeEach((to, from, next) => {
  const title = "FoodPass";
  if (
    Store.state.timeout == null ||
    (Store.state.timeout != null && Store.state.timeout > new Date())
  ) {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setUserTimeout");
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else if (to.name === undefined) {
      if (Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", to.meta.module);
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
      Store.dispatch("logout");
      next("/login");
      return;
    } else {
      Store.commit("setUserTimeout");
      if (to.name == "Login" && Store.state.hasLoggedIn) {
        Store.commit("setActiveRoute", "MemberManagement");
        next("/member-management");
        return;
      } else {
        window.document.title =
          to.meta && to.meta.title ? title + " - " + to.meta.title : title;
        next();
        return;
      }
    }
  } else {
    Store.dispatch("logout");
    next("/login");
    return;
  }
});
export default router;
