<template>
  <div class="list-margin">
    <DataTable
      :value="items"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="name"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          :currentPageReportTemplate="
        $t('showing') +
        ' ' +
        `{first}` +
        ' ' +
        $t('to') +
        ' ' +
        `{last}` +
        ' ' +
        $t('of') +
        ' ' +
        `{totalRecords}` +
        ' ' +
        $t('results')
      "
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">{{$t('itemmanagement')}}</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              :label="$t('add')"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateItem"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i
                class="search-icon cursor-pointer"
                id="search"
                @click="onSearch"
              />
              <InputText
                v-model="search"
                :placeholder="$t('search')"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> {{$t('loadingrecords')}} </template>
      <template #empty> {{$t('norecords')}} </template>
      <Column field="itemname" :header="$t('item')" sortable="true">
        <template #body="row">
          <div class="flex">
            <img :src="row.data.image" alt="" class="img-item-square" />
            <span class="margin-itemname">{{ row.data.itemname }}</span>
          </div>
        </template>
      </Column>
      <Column field="category" :header="$t('itemcategory')" sortable="true">
        <template #body="row">
          <Chip :label="row.data.category" class="chip-style" />
        </template>
      </Column>
      <Column field="detail" :header="$t('itemdetail')" sortable="true">
        <template #body="row">
          <div class="flex">
            <span class="set-details">{{ row.data.detail }}</span>
          </div>
        </template>
      </Column>
      <Column field="price" :header="$t('price')" sortable="true">
        <template #body="row">
          <span>${{ row.data.price }}</span>
        </template>
      </Column>
      <Column field="createddate" :header="$t('createdat')" sortable="true"></Column>
      <Column field="action" :header="$t('action')">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              :label="$t('edit')"
              class="edit-button"
              @click="gotoItemEdit(data.data.id)"
            />
            <PrimeButton
              :label="$t('delete')"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteItem"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service";
import ItemFactory from "@/factories/itemmanagement";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      itemIdForDelete: "",
      showPopup: false,
      items: [],
    };
  },

  mounted() {
    this.getItemList();
  },
  methods: {
    gotoCreateItem() {
      this.$router.push({ name: "ItemManagementCreate" });
    },
    gotoItemEdit(itemId) {
      this.$router.push({
        name: "ItemManagementEdit",
        params: { id: itemId },
      });
    },
    showDeletePopup(itemId) {
      this.itemIdForDelete = itemId;
      this.showPopup = true;
    },
    getItemList() {
      this.items = [];
      let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/itemmenu/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${encoded}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.items = ItemFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    deleteItem() {
      APIService.delete(`/itemmenu/delete/${this.itemIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getItemList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getItemList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getItemList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getItemList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.margin-itemname {
  margin-top: 10px;
  margin-left: 10px;
}
.set-details {
  width: 206px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chip-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  // width: 115px;
  height: 40px;

  background: #f0f0f0;
  border-radius: 40px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #141414;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
