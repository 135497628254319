import DateFormat from "@/libs/DateFormat"

export default class SubItemCategory {
    constructor(data = {}) {
        return {
            id: data.id || '',
            subcategory: data.subcategory || '',
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}