import FoodPassManagement from '@/models/foodpassmanagement'

export default class FoodPassManagementFactory {
    static createFromJson(json) {
        return new FoodPassManagement(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(FoodPassManagementFactory.createFromJson(item))
        })

        return jsonData
    }
}
