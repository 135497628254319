<template>
  <ConfirmDialog></ConfirmDialog>
  <ConfirmDialog group="templating">
    <template #message="slotProps">
      <div class="flex p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmDialog>
  <ConfirmDialog group="positionDialog"></ConfirmDialog>
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";

export default {
  components: {
    ConfirmDialog,
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    message: {
      type: String,
      default: "Do you want to delete this record?",
    },
    header: {
      type: String,
      default: "Delete Confirmation",
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openPopup() {
      this.$confirm.require({
        message: this.$t("doyouwantdelete"),
        header: this.$t("deleteconfirm"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          this.$emit("confirmYes");
        },
        reject: () => {
          this.$emit("confirmNo");
        },
        onHide: () => {
          this.$emit("confirmNo");
        },
      });
    },
  },
};
</script>