import DateFormat from "@/libs/DateFormat"

export default class Member {
    constructor(data = {}) {
        return {
            id: data.id || '',
            userid: data.userid || '',
            username: data.username || '',
            avatar: data.avatar || '',
            phoneno: data.phoneno || '',
            stampquantity: data.stampquantity || 0,
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}