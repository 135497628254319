import PickupTime from "@/models/pickuptime";

export default class PickupTimeFactory{
    static createFromJson(json) {
        return new PickupTime(json)
    }
    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(PickupTimeFactory.createFromJson(item))
        })

        return jsonData
    }
}