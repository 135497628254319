<template>
  <div :class="sectionClass">
    <label :for="id" :class="labelClass">{{ label }}</label>
    <InputNumber
      :id="id"
      :useGrouping="false"
      :modelValue="modelValue"
      :disabled="disabled"
      :placeholder="placeholder"
      :min="min"
      :max="max"
      :class="{
        'p-invalid': validation?.$error,
      }"
      :aria-describedby="`${id}-help`"
      @input="$emit('update:modelValue', $event.value)"
    />
    <span
      v-if="
        validation.$error && validation.required && validation.required.$invalid
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.required.$message.replace("Value", this.label) }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.minValue.$invalid &&
          validation.minValue)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.minValue.$message }}
    </span>
    <span
      v-else-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.maxValue.$invalid &&
          validation.maxValue)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{ validation.maxValue.$message }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    labelClass: {
      type: String,
      default: "form-label",
    },
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    validation: {
      type: Object,
      required: true,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    modelValue: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>
