export default {
  "luckyinternational": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lucky Up International Limited"])},
  "membermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員管理"])},
  "itemcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品類別"])},
  "subitemcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["副產品類別"])},
  "itemmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品管理"])},
  "subitemmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["副產品管理"])},
  "promotioncode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠碼"])},
  "pickuptime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取餐時間"])},
  "foodpassmanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食品通行證管理"])},
  "foodpassreport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食品通行證記錄"])},
  "ordermanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單管理"])},
  "cmspagemanagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CMS頁面管理"])},
  "privacypolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私隱條例"])},
  "termsandcondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條款及細則"])},
  "profilesetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人設置"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "languagesetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言設置"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶名稱"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密碼"])},
  "forgotpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘記密碼?"])},
  "forgotpasswordtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘記密碼"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵"])},
  "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密碼"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["繼續"])},
  "resetpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設密碼"])},
  "newpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密碼"])},
  "confirmpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認新密碼"])},
  "remberit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要記錄?"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
  "userid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶編號"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶名稱"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話"])},
  "stampquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["印花數量"])},
  "createdat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建日期"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行動"])},
  "memberdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員資料"])},
  "useridautogenerate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用戶編號 (自動產生)"])},
  "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭像"])},
  "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生日日期"])},
  "itemcategorydetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品類別內容"])},
  "subitemcategorydetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子產品類別內容"])},
  "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品"])},
  "itemdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品內容"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["價錢"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖像"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
  "itemname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品名稱"])},
  "selectcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇類別"])},
  "uploadfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳檔案"])},
  "subitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子產品"])},
  "subitemdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子產品內容"])},
  "selectsubitemcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇子產品類別"])},
  "subitemname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子產品名稱"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣"])},
  "promotioncodedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠碼內容"])},
  "percentagediscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["百分比折扣"])},
  "fixeddiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定折扣"])},
  "pickuptimedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件時間詳情"])},
  "foodpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食物通行證"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內容"])},
  "mealqty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["餐劵數量"])},
  "expirydate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到期日"])},
  "foodpassdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食物通行證內容"])},
  "foodpassname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["食物通行證名稱"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單 #"])},
  "orderdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單日期"])},
  "remainingqty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["剩餘次數"])},
  "redeemedqty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已兌換次數"])},
  "paymentmethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀況"])},
  "pickupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取件日期"])},
  "totalprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總價"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "orderdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單詳情"])},
  "itemlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品清單"])},
  "addnewitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加新項目"])},
  "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小計"])},
  "ordertotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計訂單"])},
  "privacypolicydetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隱私政策頁面詳細信息"])},
  "termsandconditionsdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條款和條件頁面詳細信息"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["內容"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消除"])},
  "dateformat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY-MM-DD"])},
  "loadingrecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在加載記錄，請稍候..."])},
  "norecords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有找到記錄。"])},
  "selectitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇物品"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看法"])},
  "qty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數量"])},
  "ordersummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單摘要"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英語"])},
  "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中國人"])},
  "deleteconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除確認"])},
  "doyouwantdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你想刪除這條記錄嗎？"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["的"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結果"])}
}