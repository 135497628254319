<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div v-if="$route.params.id" class="flex order-form-title">{{$t('itemmanagement')}} / {{ $t('edit') }}</div>
        <div v-else class="flex order-form-title">{{$t('itemmanagement')}} / {{ $t('add') }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">{{$t('itemdetail')}}</div>
            </div>
            <div class="col-9">
              <div class="mb-4">
                <label for="photo" class="form-label">{{$t('image')}}</label>
                <div
                  class="photo-section cursor-pointer"
                  :class="vmodel.image ? 'remove-padding' : ''"
                  @click="openFileUpload"
                >
                  <!-- :src="getImagePath(vmodel.image)" -->
                  <img
                    v-if="vmodel.image !== ''"
                    :src="vmodel.image"
                    alt=""
                    class="upload-img"
                    height="160"
                    width="160"
                  />
                  <span v-if="vmodel.image == ''" class="upload-icon"></span>
                  <div v-if="vmodel.image == ''" class="upload-text">
                    {{$t('uploadfile')}}
                  </div>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  @select="onAdvancedUpload($event)"
                  accept=".png, .jpg, .jpeg"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
                <div
                  v-if="
                    v$.vmodel.image.$error &&
                    v$.vmodel.image.required &&
                    v$.vmodel.image.required.$invalid
                  "
                  class="p-error mt-2"
                >
                  {{
                    v$.vmodel.image.required.$message.replace("Value", "Image")
                  }}
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.categoryid"
                    :validation="v$.vmodel.categoryid"
                    :placeholder="$t('selectcategory')"
                    sectionClass="field mt-1"
                    id="category"
                    :label="$t('category')"
                    :options="categoryList"
                    optionLabel="category"
                    optionValue="id"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.itemname"
                    :validation="v$.vmodel.itemname"
                    sectionClass="field mt-1"
                    id="itemname"
                    :label="$t('itemname')"
                    :placeholder="$t('itemname')"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-12">
                  <InputTextRequire
                    v-model="vmodel.detail"
                    :validation="v$.vmodel.detail"
                    sectionClass="field mt-1"
                    id="username"
                    :label="$t('item')"
                    :placeholder="$t('item')"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputGroup
                    v-model="vmodel.price"
                    :validation="v$.vmodel.price"
                    placeholder="Price"
                    sectionClass="field mt-1"
                    id="price"
                    :min="1"
                    :label="$t('price')"
                  />
                </div>
              </div>
              <div class="mt-4">
                <PrimeButton
                  :label="$t('add')"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createItem"
                ></PrimeButton>
                <PrimeButton
                  :label="$t('edit')"
                  class="order-form-button"
                  v-else
                  @click="createItem"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minValue, maxValue } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      categoryList: [],
      submitted: false,
      isEditMode: false,
      image: "",
      vmodel: {
        image: "",
        categoryid: "",
        itemname: "",
        detail: "",
        price: 1,
      },
      invalidDates: null,
    };
  },
  validations() {
    return {
      vmodel: {
        image: { required },
        categoryid: { required },
        itemname: { required, max: maxLength(50) },
        detail: { required, max: maxLength(250) },
        price: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(99999999),
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getItemById(this.$route.params.id);
    }
    this.getCategory();
  },
  methods: {
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createItem() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          image: this.image,
          categoryid: this.vmodel.categoryid,
          itemname: this.vmodel.itemname,
          detail: this.vmodel.detail,
          price: this.vmodel.price,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/itemmenu", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ItemManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/itemmenu", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "ItemManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid Image",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/itemmenu/menu", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.image = response.data.data.name;
              this.vmodel.image = response.data.data.path;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
    getCategory() {
      APIService.get("/itemcategory/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.categoryList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async getItemById() {
      APIService.getById("/itemmenu", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.image = response.data.data.image;
            this.vmodel.image = response.data.data.imagepath;
            this.vmodel.categoryid = response.data.data.categoryid;
            this.vmodel.itemname = response.data.data.itemname;
            this.vmodel.detail = response.data.data.detail;
            this.vmodel.price = response.data.data.price;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "ItemManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-padding {
  padding: 0px !important;
}
.photo-section {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;

  width: 512px;
  height: 160px;

  /* White */

  background: #ffffff;
  /* Grey/20 */

  border: 1px solid #ccc9d6;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.upload-img {
  border-radius: 1px;
}
.upload-text {
  width: 96px;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;

  color: #e77c30;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>
