<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div class="flex order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="grid">
          <div class="col-4">
            <div class="order-mini-card">
              <div class="grid align-item-center justify-content-center">
                <img
                  src="../../assets/images/avatar.png"
                  class="img-style col-12"
                />
                <span class="profile-name col-12">
                  {{ name }}
                </span>
              </div>
              <div class="col-12">
                <div class="grid">
                  <span class="profile-text-title col-6 justify-content-start">
                    User ID
                  </span>
                  <span class="profile-text col-6 justify-content-end">
                    -
                  </span>
                </div>
              </div>
              <div class="col-12">
                <div class="grid">
                  <span class="profile-text-title col-6 justify-content-start">
                    Birthday
                  </span>
                  <span class="profile-text col-6 justify-content-end">
                    -
                  </span>
                </div>
              </div>
              <div class="col-12">
                <div class="grid">
                  <span class="profile-text-title col-6 justify-content-start">
                    Email
                  </span>
                  <span class="profile-text col-6 justify-content-end">
                    {{ email }}
                  </span>
                </div>
              </div>
              <div class="col-12">
                <div class="grid">
                  <span class="profile-text-title col-6 justify-content-start">
                    Phone
                  </span>
                  <span class="profile-text col-6 justify-content-end">
                    -
                  </span>
                </div>
              </div>
              <div class="col-12 mt-auto">
                <div class="grid">
                  <PrimeButton
                    label="Logout"
                    class="logout-button col-12"
                    @click="logout"
                  ></PrimeButton>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="order-large-card">
              <div class="grid">
                <div class="col-3">
                  <div class="order-form-sub-title">Change Password</div>
                </div>
                <div class="col-9">
                  <div class="field">
                    <div class="flex-row">
                      <label for="password" class="form-label float-left"
                        >Current Password</label
                      >
                    </div>
                    <span class="p-input-icon-right">
                      <i
                        :class="
                          showCurrentPassword
                            ? 'password-hide-icon cursor-pointer'
                            : 'eye-icon cursor-pointer'
                        "
                        @click="showCurrentPassword = !showCurrentPassword"
                      />
                      <InputText
                        id="password"
                        placeholder="Current Password"
                        :type="currentPasswordInputType"
                        class="w-full"
                        v-model="vmodel.current_password"
                        aria-describedby="current_password-help"
                        :class="{
                          'p-invalid': v$.vmodel.current_password.$error,
                        }"
                      />
                    </span>
                    <span
                      v-if="
                        v$.vmodel.current_password.$error &&
                        v$.vmodel.current_password.required.$invalid
                      "
                      id="current_password-help"
                      class="p-error text-left mt-2"
                    >
                      {{
                        v$.vmodel.current_password.required.$message.replace(
                          "Value",
                          "Current Password"
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.current_password.$error &&
                        v$.vmodel.current_password.min.$invalid
                      "
                      id="current_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.current_password.min.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.current_password.$error &&
                        v$.vmodel.current_password.max.$invalid
                      "
                      id="current_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.current_password.max.$message }}
                    </span>
                  </div>
                  <div class="field mt-1">
                    <div class="flex-row">
                      <label for="password" class="form-label float-left"
                        >New Password</label
                      >
                    </div>
                    <span class="p-input-icon-right">
                      <i
                        :class="
                          showNewPassword
                            ? 'password-hide-icon cursor-pointer'
                            : 'eye-icon cursor-pointer'
                        "
                        @click="showNewPassword = !showNewPassword"
                      />
                      <InputText
                        id="password"
                        placeholder="New Password"
                        :type="newPasswordInputType"
                        class="w-full"
                        v-model="vmodel.new_password"
                        aria-describedby="new_password-help"
                        :class="{
                          'p-invalid': v$.vmodel.new_password.$error,
                        }"
                      />
                    </span>
                    <span
                      v-if="
                        v$.vmodel.new_password.$error &&
                        v$.vmodel.new_password.required.$invalid
                      "
                      id="new_password-help"
                      class="p-error text-left mt-2"
                    >
                      {{
                        v$.vmodel.new_password.required.$message.replace(
                          "Value",
                          "New Password"
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.new_password.$error &&
                        v$.vmodel.new_password.min.$invalid
                      "
                      id="new_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.new_password.min.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.new_password.$error &&
                        v$.vmodel.new_password.max.$invalid
                      "
                      id="new_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.new_password.max.$message }}
                    </span>
                  </div>
                  <div class="field mt-1">
                    <div class="flex-row">
                      <label for="password" class="form-label float-left"
                        >Confirm New Password</label
                      >
                    </div>
                    <span class="p-input-icon-right">
                      <i
                        :class="
                          showConfirmPassword
                            ? 'password-hide-icon cursor-pointer'
                            : 'eye-icon cursor-pointer'
                        "
                        @click="showConfirmPassword = !showConfirmPassword"
                      />
                      <InputText
                        id="password"
                        placeholder="Confirm New Password"
                        :type="confirmPasswordInputType"
                        class="w-full"
                        v-model="vmodel.confirm_password"
                        aria-describedby="confirm_password-help"
                        :class="{
                          'p-invalid': v$.vmodel.confirm_password.$error,
                        }"
                      />
                    </span>
                    <span
                      v-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.required.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error text-left mt-2"
                    >
                      {{
                        v$.vmodel.confirm_password.required.$message.replace(
                          "Value",
                          "Confirm New Password"
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.min.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.confirm_password.min.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.max.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error mt-2"
                    >
                      {{ v$.vmodel.confirm_password.max.$message }}
                    </span>
                    <span
                      v-else-if="
                        v$.vmodel.confirm_password.$error &&
                        v$.vmodel.confirm_password.sameAs.$invalid
                      "
                      id="confirm_password-help"
                      class="p-error text-left mt-2"
                    >
                      New Password && Confirm New Password must be match
                    </span>
                  </div>
                  <div class="mt-6">
                    <PrimeButton
                      label="Change"
                      class="order-form-button ml-0"
                      @click="changePassword"
                    ></PrimeButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minLength, sameAs } from "@vuelidate/validators";
import APIService from "@/services/api-service.js";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      not_match: false,
      title: "Profile Setting",
      name: "",
      email: "",
      vmodel: {
        current_password: null,
        new_password: null,
        confirm_password: null,
      },
    };
  },
  validations() {
    return {
      vmodel: {
        current_password: { required, min: minLength(8), max: maxLength(20) },
        new_password: { required, min: minLength(8), max: maxLength(20) },
        confirm_password: {
          required,
          min: minLength(8),
          max: maxLength(20),
          sameAs: sameAs(this.vmodel.new_password),
        },
      },
    };
  },
  computed: {
    currentPasswordInputType() {
      if (this.showCurrentPassword) {
        return "text";
      }
      return "password";
    },
    newPasswordInputType() {
      if (this.showNewPassword) {
        return "text";
      }
      return "password";
    },
    confirmPasswordInputType() {
      if (this.showConfirmPassword) {
        return "text";
      }
      return "password";
    },
  },
  mounted() {
    this.getUserInfo(this.$store.state.userInfo._id);
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
    async getUserInfo(id) {
      APIService.getById("/admin", id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.name = response.data.data.username;
            this.email = response.data.data.email;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "ProfileSetting" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async changePassword() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          password: this.vmodel.current_password,
          newpassword: this.vmodel.new_password,
        };
        APIService.put(
          "/admin/changepassword",
          payload,
          this.$store.state.userInfo._id
        )
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "ProfileSetting" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-card {
  background: transparent;
  padding: 0px !important;
  gap: 24px;
  box-shadow: none;
  border-radius: none;
}
.order-mini-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 24px;

  // width: 400px;

  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(24, 28, 33, 0.05);
  border-radius: 4px;
  height: 100%;
}
.order-large-card {
  // display: flex;
  // flex-direction: row;
  // align-items: flex-start;
  padding: 40px;
  // gap: 40px;

  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(24, 28, 33, 0.05);
  border-radius: 4px;
  height: 100%;
}
.logout-button {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;

  height: 56px;

  background: #f0f0f0;
  border: 1px solid #cccac8;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;

  color: #141414;
}
.logout-button:hover {
  background: #f0f0f0 !important;
  border: 1px solid #cccac8 !important;
  color: #141414 !important;
}
.img-style {
  width: 80px;
  height: 80px;
}
.profile-name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-transform: capitalize;
  font-feature-settings: "liga" off;

  color: #141414;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.profile-text-title {
  display: flex;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;
  font-feature-settings: "liga" off;

  color: rgba(20, 20, 20, 0.6);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.profile-text {
  display: flex;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #141414;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
