import DateFormat from "@/libs/DateFormat"

export default class SubItemManagement {
    constructor(data = {}) {
        return {
            id: data.id || '',
            image: data.image || '',
            subitemname: data.subitemname || '',
            subcategory: data.subcategory || '',
            price: data.price || 0,
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}