<template>
  <div :class="sectionClass">
    <label :for="id" class="form-label">{{ label }}</label>
    <CascadeSelect
      :id="id"
      :modelValue="modelValue"
      :options="options"
      :optionLabel="optionLabel"
      :optionValue="optionValue"
      :optionGroupLabel="optionGroupLabel"
      :optionGroupChildren="optionGroupChildren"
      :placeholder="placeholder"
      :disabled="disabled"
      arrow="pi pi-down"
      :class="{
        'p-invalid':
          (validated && customfield && validation) ||
          (validation && validation.$error),
      }"
      :aria-describedby="`${id}-help`"
      @change="$emit('update:modelValue', $event.value)"
    />
    <span
      v-if="
        (validated && customfield && validation) ||
        (validation &&
          validation.$error &&
          validation.required.$invalid &&
          validation.required)
      "
      :id="`${id}-help`"
      class="p-error text-left mt-2"
    >
      {{
        validated && customfield && validation
          ? validation.$message.replace("Value", this.label)
          : validation.required.$message.replace("Value", this.label)
      }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    sectionClass: {
      type: String,
      default: "field",
    },
    id: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: true,
      default: "",
    },
    validation: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    optionLabel: {
      type: String,
      default: "",
    },
    optionValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    optionGroupLabel: {
      type: String,
    },
    optionGroupChildren: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customfield: {
      type: Boolean,
      default: false,
    },
    validated: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
