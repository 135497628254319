<template>
  <div class="list-margin">
    <DataTable
      :value="users"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="userid"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      :currentPageReportTemplate="
        $t('showing') +
        ' ' +
        `{first}` +
        ' ' +
        $t('to') +
        ' ' +
        `{last}` +
        ' ' +
        $t('of') +
        ' ' +
        `{totalRecords}` +
        ' ' +
        $t('results')
      "
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">
            {{ $t("membermanagement") }}
          </div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              :label="$t('add')"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateMember"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i
                class="search-icon cursor-pointer"
                id="search"
                @click="onSearch"
              />
              <InputText
                v-model="search"
                :placeholder="$t('search')"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> {{ $t("loadingrecords") }} </template>
      <template #empty> {{ $t("norecords") }} </template>
      <Column field="userid" :header="$t('userid')" sortable="true"></Column>
      <Column field="username" :header="$t('user')" sortable="true">
        <template #body="row">
          <div class="flex">
            <img :src="row.data.avatar" alt="" class="img-item" />
            <span class="margin-username">{{ row.data.username }}</span>
          </div>
        </template>
      </Column>
      <Column field="phoneno" :header="$t('phone')" sortable="true"></Column>
      <Column
        field="stampquantity"
        :header="$t('stampquantity')"
        sortable="true"
      ></Column>
      <Column
        field="createddate"
        :header="$t('createdat')"
        sortable="true"
      ></Column>
      <Column field="action" :header="$t('action')">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              :label="$t('edit')"
              class="edit-button"
              @click="gotoMemberEdit(data.data.id)"
            />
            <PrimeButton
              :label="$t('delete')"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteMember"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import MemberFactory from "@/factories/member.js";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      memberIdForDelete: "",
      showPopup: false,
      users: [],
      confirmType: "",
    };
  },
  mounted() {
    this.getMemberList();
  },
  methods: {
    gotoCreateMember() {
      this.$router.push({ name: "MemberManagementCreate" });
    },
    gotoMemberEdit(memberId) {
      this.$router.push({
        name: "MemberManagementEdit",
        params: { id: memberId },
      });
    },
    showDeletePopup(memberId) {
      this.memberIdForDelete = memberId;
      this.showPopup = true;
    },
    getMemberList() {
      this.users = [];
      let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/member/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${encoded}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecords;
          this.users = MemberFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    deleteMember() {
      APIService.delete(`/member/delete/${this.memberIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getMemberList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getMemberList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getMemberList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getMemberList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.margin-username {
  margin-top: 10px;
  margin-left: 10px;
}
</style>
