<template>
  <div class="list-margin">
    <DataTable
      :value="foodpasses"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="foodpassid"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
           :currentPageReportTemplate="
        $t('showing') +
        ' ' +
        `{first}` +
        ' ' +
        $t('to') +
        ' ' +
        `{last}` +
        ' ' +
        $t('of') +
        ' ' +
        `{totalRecords}` +
        ' ' +
        $t('results')
      "
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">{{$t('foodpassmanagement')}}</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              :label="$t('add')"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateFoodPass"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i
                class="search-icon cursor-pointer"
                id="search"
                @click="onSearch"
              />
              <InputText
                v-model="search"
                :placeholder="$t('search')"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading>{{$t('loadingrecords')}}</template>
      <template #empty> {{$t('norecords')}} </template>
      <Column field="name" :header="$t('foodpass')" sortable="true">
        <template #body="row">
          <div class="flex">
            <img :src="row.data.image" alt="" class="img-item-square" />
            <span class="margin-username">{{ row.data.name }}</span>
          </div>
        </template>
      </Column>
      <Column field="detail" :header="$t('details')" sortable="true">
        <template #body="row">
          <div class="flex">
            <span class="set-details">{{ row.data.detail }}</span>
          </div>
        </template>
      </Column>
      <Column field="mealqty" :header="$t('mealqty')" sortable="true"></Column>
      <Column field="expirydate" :header="$t('expirydate')" sortable="true"></Column>
      <Column field="price" :header="$t('price')" sortable="true">
        <template #body="row">
          <span>$ {{ row.data.price }}</span>
        </template>
      </Column>
      <Column field="createddate" :header="$t('createdat')" sortable="true"></Column>
      <Column field="action" :header="$t('action')">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              :label="$t('edit')"
              class="edit-button"
              @click="gotoFoodPassEdit(data.data.id)"
            />
            <PrimeButton
              :label="$t('delete')"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteFoodPass"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import FoodPassFactory from "@/factories/foodpassmanagement";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      foodpassIdForDelete: "",
      showPopup: false,
      foodpasses: [],
    };
  },

  mounted() {
    this.getFoodPassList();
  },
  methods: {
    gotoCreateFoodPass() {
      this.$router.push({ name: "FoodpassManagementCreate" });
    },
    gotoFoodPassEdit(foodpassId) {
      this.$router.push({
        name: "FoodpassManagementEdit",
        params: { id: foodpassId },
      });
    },
    showDeletePopup(foodpassId) {
      this.foodpassIdForDelete = foodpassId;
      this.showPopup = true;
    },
    getFoodPassList() {
      this.foodpasses = [];
      let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/foodpassmanagement/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${encoded}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.foodpasses = FoodPassFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    deleteFoodPass() {
      APIService.delete(
        `/foodpassmanagement/delete/${this.foodpassIdForDelete}`
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getFoodPassList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getFoodPassList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getFoodPassList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getFoodPassList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.margin-username {
  margin-top: 10px;
  margin-left: 10px;
}
.set-details {
  width: 177px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
