<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/images/luckyfood-logo.png" /></span>
    </div>
    <div class="menu mt-0">
      <router-link
        :to="{ name: 'MemberManagement' }"
        id="Member"
        :class="
          $store.state.activeRoute === 'MemberManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="member-icon"></i>
        <span class="menu-text">{{$t("membermanagement")}}</span>
      </router-link>
         <router-link
        :to="{ name: 'ItemCategory' }"
        id="ItemCategory"
        :class="
          $store.state.activeRoute === 'ItemCategory'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="item-category-icon"></i>
        <span class="menu-text">{{$t("itemcategory")}}</span>
      </router-link>
         <router-link
        :to="{ name: 'SubItemCategory' }"
        id="SubItemCategory"
        :class="
          $store.state.activeRoute === 'SubItemCategory'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="subitem-category-icon"></i>
        <span class="menu-text">{{$t("subitemcategory")}}</span>
      </router-link>
      <router-link
        :to="{ name: 'ItemManagement' }"
        id="ItemManagement"
        :class="
          $store.state.activeRoute === 'ItemManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="item-mgmt-icon"></i>
        <span class="menu-text">{{$t("itemmanagement")}}</span>
      </router-link>
      <router-link
        :to="{ name: 'SubItemManagement' }"
        id="SubItemManagement"
        :class="
          $store.state.activeRoute === 'SubItemManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="subitem-mgmt-icon"></i>
        <span class="menu-text">{{$t("subitemmanagement")}}</span>
      </router-link>
      <router-link
        :to="{ name: 'PromotionCode' }"
        id="PromotionCode"
        :class="
          $store.state.activeRoute === 'PromotionCode'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="promotion-icon"></i>
        <span class="menu-text">{{$t("promotioncode")}}</span>
      </router-link>
      <router-link
        :to="{ name: 'PickupTime' }"
        id="PickupTime"
        :class="
          $store.state.activeRoute === 'PickupTime'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="pickup-icon"></i>
        <span class="menu-text">{{$t("pickuptime")}}</span>
      </router-link>       
		<router-link
        :to="{ name: 'FoodpassManagement' }"
        id="FoodpassManagement"
        :class="
          $store.state.activeRoute === 'FoodpassManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="foodpass-mgmt-icon"></i>
        <span class="menu-text">{{$t("foodpassmanagement")}}</span>
      </router-link>
    <router-link
        :to="{ name: 'FoodpassReport' }"
        id="FoodpassReport"
        :class="
          $store.state.activeRoute === 'FoodpassReport'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="food-report-icon"></i>
        <span class="menu-text">{{$t("foodpassreport")}}</span>
      </router-link>
      <router-link
        :to="{ name: 'OrderManagement' }"
        id="OrderManagement"
        :class="
          $store.state.activeRoute === 'OrderManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
       <i class="order-icon"></i>
        <span class="menu-text">{{$t("ordermanagement")}}</span>
      </router-link>
     <div
        class="menu-item cursor-pointer"
        @click="visibleCMSMenu = !visibleCMSMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">{{$t("cmspagemanagement")}}</span>
        <i
          class="margin"
          :class="{
            ' chevron-down-icon': !visibleCMSMenu,
            ' chevron-up-icon': visibleCMSMenu,
          }"
        ></i>
      </div>
      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'PrivacyPolicy' }"
        id="PrivacyPolicy"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">{{$t("privacypolicy")}}</span>
      </router-link>

      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'TermsAndConditions' }"
        id="TermsAndConditions"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">{{$t("termsandcondition")}}</span>
      </router-link>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "SidebarMenu",
  data() {
    return {
      visibleLeft: true,
      visibleCMSMenu: false,
      visibleDDMenu: false,
    };
  },
};
</script>
<style scoped>
@import "@/assets/css/sidebar.css";

.dropdown-list-padding {
  padding-left: 57px;
}

.margin {
  margin-left: 17px !important;
  margin-top: 9px;
}
</style>