import DateFormat from "@/libs/DateFormat";

export default class PromotionCode {
    constructor(data = {}) {
        return {
            id: data.id || '',
            code: data.code || '',
            description: data.description || '',
            type: data.type || '',
            discount: data.discount || 0,
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}