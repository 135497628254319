import SubItemManagement from '@/models/subitemmanagement'

export default class SubItemManagementFactory {
    static createFromJson(json) {
        return new SubItemManagement(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(SubItemManagementFactory.createFromJson(item))
        })

        return jsonData
    }
}
