<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div class="order-form-title"> {{ $t("languagesetting") }}</div>
      <div class="language-box w-full">
        <div class="grid col-12">
          <div class="col-6">
            <RadioButton
              id="english"
              type="radio"
              name="languageenglish"
              value="en"
              v-model="$store.state.language"
              @change="changeLanguage('en')"
            />
            <label for="english" class="form-label ml-2">
              {{ $t("english") }}
            </label>
          </div>
          <div class="col-6">
            <RadioButton
              id="chinese"
              name="languagechinese"
              value="zh"
              v-model="$store.state.language"
              @change="changeLanguage('zh')"
            />
            <label for="chinese" class="form-label ml-2">
              {{ $t("chinese") }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "@/store/index";

export default {
  data() {
    return {
      language: "",
    };
  },
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language;
      Store.commit("setLanguage", language);
      this.$router.go();
    },
  },
};
</script>

<style>
</style>