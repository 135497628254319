<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
      <div class="col-12 order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="order-form-sub-title">{{$t('orderdetail')}}</div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.orderno"
                    :validation="v$.vmodel.orderno"
                    :placeholder="$t('order')"
                    sectionClass="field mt-1"
                    id="order"
                    :label="$t('order')"
                    :disabled="true"
                    class="disable-text-field"
                  />
                </div>
                <div class="col-6">
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    :placeholder="$t('dateformat')"
                    sectionClass="field mt-1"
                    id="orderdate"
                    :label="$t('orderdate')"
                    v-if="!isEditMode"
                  />
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    :placeholder="$t('orderdate')"
                    sectionClass="field mt-1"
                    :disabled="true"
                    id="orderdate"
                    :label="$t('orderdate')"
                    v-else
                  />
                </div>
              </div>
              <DropDownRequire
                v-model="vmodel.memberid"
                :validation="v$.vmodel.memberid"
                :placeholder="$t('user')"
                sectionClass="field mt-1"
                :disabled="true"
                id="user"
                :label="$t('user')"
                :options="memberList"
                optionLabel="username"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.pickuptimeid"
                :validation="v$.vmodel.pickuptimeid"
                :placeholder="$t('pickuptime')"
                sectionClass="field mt-1"
                :disabled="true"
                id="pickuptime"
                :label="$t('pickuptime')"
                :options="pickuptimeList"
                optionLabel="pickuptime"
                optionValue="id"
              />
              <InputDate
                v-model="vmodel.pickupdate"
                :validation="v$.vmodel.pickupdate"
                :placeholder="$t('dateformat')"
                sectionClass="field mt-1"
                id="orderdate"
                :disabled="true"
                :label="$t('pickupdate')"
              />
              <label for="box" class="form-label field">{{$t('itemlist')}}</label>
              <div id="box" class="box">
                <div
                  class="grid"
                  v-for="(item, index) in vmodel.items"
                  :id="item.id"
                  :key="item.id"
                >
                  <div class="col-5">
                    <DropDownCustom
                      v-model="vmodel.items[index].itemid"
                      :validation="
                        v$.vmodel.items.$each.$response.$errors[index].itemid[0]
                      "
                      :placeholder="$t('itemname')"
                      sectionClass="field"
                      id="item"
                      :label="$t('itemname')"
                      :options="itemList"
                      optionLabel="itemname"
                      optionValue="id"
                      optionGroupLabel="itemname"
                      :optionGroupChildren="['subitemmenu']"
                      optionDisabled="disabled"
                      class="width-dropdown"
                      :customfield="true"
                      :disabled="true"
                      :validated="validated"
                      @update:modelValue="getItemListDetails(index)"
                    />
                  </div>
                  <div class="col-3">
                    <div class="grid">
                      <div class="col-12 flex justify-content-center">
                        <div class="quantity-field">
                          <PrimeButton
                            class="value-button decrease-button"
                            :disabled="true"
                            :class="{
                              'p-invalid':
                                validated &&
                                v$.vmodel.items.$each.$response.$errors[index]
                                  .qty[0],
                            }"
                          >
                            <span class="decrement-icon"></span>
                          </PrimeButton>
                          <InputNumber
                            v-model="vmodel.items[index].qty"
                            class="number"
                            labelClass="hidden"
                            :disabled="true"
                            :class="{
                              'p-invalid':
                                validated &&
                                v$.vmodel.items.$each.$response.$errors[index]
                                  .qty[0],
                            }"
                          />
                          <!-- <div class="number">{{ vmodel.items[index].qty === 0 ? 'Qty' : vmodel.items[index].qty }}</div> -->
                          <PrimeButton
                            class="value-button increase-button"
                            :disabled="true"
                            :class="{
                              'p-invalid':
                                validated &&
                                v$.vmodel.items.$each.$response.$errors[index]
                                  .qty[0],
                            }"
                          >
                            <span class="increment-icon"></span>
                          </PrimeButton>
                        </div>
                      </div>
                      <div class="col-12">
                        <span
                          v-if="
                            validated &&
                            v$.vmodel.items.$each.$response.$errors[index]
                              .qty[0]
                          "
                          class="p-error text-left mt-2"
                        >
                          Minimum 1 Qty required
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-3 box-add-sub field"></div> -->
                  <div class="col-3" style="margin-top: 33px">
                    <InputGroupCustom
                      v-model="vmodel.items[index].price"
                      :validation="
                        v$.vmodel.items.$each.$response.$errors[index].price[0]
                      "
                      :placeholder="$t('price')"
                      sectionClass="field"
                      labelClass="hidden"
                      id="price"
                      :label="$t('price')"
                      :disabled="true"
                      :customfield="true"
                      :validated="validated"
                    />
                  </div>
                  <div class="col-1 field" style="margin-top: 35px">
                    <PrimeButton
                      icon="bin-icon"
                      :disabled="true"
                      class="bin-button"
                    />
                  </div>
                </div>
                <div class="grid">
                  <div class="col-4 field">
                    <PrimeButton
                      :label="$t('addnewitem')"
                      icon=""
                      :disabled="true"
                      class="add-item-button"
                    />
                  </div>
                </div>
              </div>
              <div class="box2">
                <div class="grid">
                  <div class="col-6 field order-form-label">{{$t('subtotal')}}</div>
                  <div
                    class="col-6 field form-label number-form-order margin-for-list"
                  >
                    $ {{ vmodel.subtotal.toFixed(1) }}
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6 mt-2">
                    <span class="p-input-icon-right">
                      <i
                        class="apply-icon cursor-pointer"
                        @click="getPromocode"
                        v-if="!applyPromocode"
                      />
                      <i
                        class="close-icon cursor-pointer"
                        @click="clearPromocode"
                        v-else
                      />
                      <InputText
                        v-model="vmodel.promocode"
                        :disabled="true"
                        id="promotioncode"
                        :placeholder="$t('promotioncode')"
                      />
                    </span>
                  </div>
                  <div
                    class="col-6 field form-label discount-font number-form-order mt-4 margin-for-list"
                  >
                    -$ {{ discountprice.toFixed(1) }}
                  </div>
                </div>
                <div class="grid mt-1">
                  <div class="col-6 field order-form-label">{{$t('ordertotal')}}</div>
                  <div
                    class="col-6 field total-font form-label number-form-order margin-for-list"
                  >
                    $ {{ vmodel.total.toFixed(1) }}
                  </div>
                </div>
              </div>
              <div v-if="isEditMode == true">
                <InputTextRequire
                  v-model="vmodel.paymentmethod"
                  :validation="v$.vmodel.paymentmethod"
                  sectionClass="field mt-4"
                  id="payment"
                  :label="$t('paymentmethod')"
                  :disabled="true"
                  class="disable-text-field"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api-service.js";
import { required, minValue, helpers } from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      pickuptimeList: [],
      memberList: [],
      itemList: [],
      isEditMode: false,
      title: "Order Management / View",
      discounttype: "",
      discountprice: 0,
      applyPromocode: false,
      vmodel: {
        orderno: "",
        orderdate: "",
        memberid: "",
        pickuptimeid: "",
        pickupdate: "",
        promocodeid: "",
        promocode: "",
        subtotal: 0,
        discount: 0,
        total: 0,
        paymentmethod: "",
        items: [
          {
            itemid: "",
            itemname: "",
            categoryid: "",
            categoryname: "",
            qty: 0,
            price: 0,
          },
        ],
      },
      submitted: false,
      validated: false,
    };
  },
  validations() {
    return {
      vmodel: {
        orderno: { required },
        orderdate: { required },
        memberid: { required },
        pickuptimeid: { required },
        pickupdate: { required },
        subtotal: { required },
        discount: { required },
        total: { required },
        paymentmethod: { required },
        items: {
          $each: helpers.forEach({
            itemid: { required },
            itemname: { required },
            categoryid: { required },
            categoryname: { required },
            qty: { required, minValue: minValue(1) },
            price: { required, minValue: minValue(1) },
          }),
        },
      },
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getOrderById();
    }
    this.getMember();
    this.getPickupTime();
    this.getItem();
  },
  methods: {
    async getOrderById() {
      APIService.getById("/ordermanagement", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.orderno = response.data.data.orderno;
            this.vmodel.orderdate = DateFormat.formatDate(
              response.data.data.orderdate
            );
            const data = response.data.data;
            this.vmodel.memberid = data.memberid;
            this.vmodel.pickuptimeid = data.pickuptimeid;
            this.vmodel.pickupdate = DateFormat.formatDate(
              response.data.data.pickupdate
            );
            this.vmodel.promocodeid = data.promocodeid;
            this.vmodel.promocode = data.promocode;
            if (data.discount) {
              this.discountprice = data.discount;
            } else {
              this.discountprice = 0;
            }
            this.discounttype = data.promocodediscounttype;
            this.vmodel.subtotal = data.subtotal;
            this.vmodel.discount = data.discount;
            this.vmodel.total = data.total;
            this.vmodel.paymentmethod = data.paymentmethod;
            this.vmodel.items = data.items;
            if (this.vmodel.promocodeid && this.vmodel.promocode) {
              this.applyPromocode = true;
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "OrderManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getMember() {
      APIService.get("/member/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.memberList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPickupTime() {
      APIService.get("/pickupdatetime/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.pickuptimeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getItem() {
      APIService.get("/itemmenu/dropdown/order")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getItemListDetails(index) {
      let check = false;
      check = this.itemList
        .filter((x) => x.id === this.vmodel.items[index].itemid)
        .map(() => {
          return true;
        })[0];
      if (check) {
        this.itemList
          .filter((x) => x.id === this.vmodel.items[index].itemid)
          .map((data) => {
            this.vmodel.items[index].itemname = data.itemname;
            this.vmodel.items[index].categoryid = data.cetegoryid;
            this.vmodel.items[index].categoryname = data.cetegory;
          });
      } else {
        let found;
        for (let p of this.itemList) {
          if (p.subitemmenu) {
            for (let i of p.subitemmenu) {
              if (i.id === this.vmodel.items[index].itemid) {
                found = i;
              }
            }
          }
        }
        this.vmodel.items[index].itemname = found.itemname;
        this.vmodel.items[index].categoryid = found.cetegoryid;
        this.vmodel.items[index].categoryname = found.cetegory;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: #e5e3e1;
    border: 1px solid #cccac8;
  }
}

::v-deep(.width-dropdown) {
  .p-inputtext {
    width: 186px;
  }
}

.quantity-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 150px;
  border-radius: 4px;
  margin-top: 33px;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 52px;
  padding: 13px;
  background: #ffffff;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: #ffffff;
}

.quantity-field .value-button:active {
  background: #ffffff;
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 0 0 4px;
  // border-right: 1px solid #e6e3e1 !important;
  width: 50px;
  height: 56px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 0 4px 4px 0;
  // border-left: 1px solid #e6e3e1 !important;
  // border-left: none;
  width: 50px;
  height: 56px;
}

::v-deep(.quantity-field .number) {
  .p-inputtext {
    display: flex;
    text-align: center;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    // margin: 0px;
    width: 50px;
    height: 56px;
    box-sizing: border-box;
    background: white;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    // padding-top: 15px;
    // padding-right: 5px;
    // padding-left: 1px;
    color: #141414;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.qty-style {
  width: 36px;
  margin-top: -21px;
  border-radius: unset;
  border-left: none;
  border-right: none;
  padding-left: 11px;
}

.quantity-field .number::selection {
  background: none;
}

.margin-for-list {
  // margin-left: 387px;
  text-align: right;
}

.item-name-width {
  width: 215px;
}

::v-deep(.p-button.p-invalid) {
  border-color: #dc3545;
}
.discount-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #28aa45;
}

.total-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #e77c30;
}
</style>
