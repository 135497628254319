import DateFormat from "@/libs/DateFormat"
import ItemFactory from "@/factories/itemmanagement";

export default class OrderManagement {
    constructor(data = {}) {
        return {
            id: data.id || '',
            orderno: data.orderno || '',
            orderdate: DateFormat.formatDate(data.orderdate) || '',
            pickuptime: data.pickuptime || '',
            pickupdate: DateFormat.formatDate(data.pickupdate) || '',
            promocode: data.promocode || '',
            discount: data.discount || 0,
            subtotal: data.subtotal || 0,
            total: data.total || 0,
            paymentmethod: data.paymentmethod || '',
            status: data.status || '',
            items: ItemFactory.createFromJsonArray(data.items) || '',
        }
    }
}