import DateFormat from "@/libs/DateFormat"

export default class FoodPassReport {
    constructor(data = {}) {
        return {
            id: data.id || '', 
            orderid: data.orderid || '',
            createddate: DateFormat.formatDate(data.createddate) || '',
            username: data.username || '',
            phoneno: data.phoneno || '',
            foodpassname: data.foodpassname || '',
            totalqty: data.totalqty || 0,
            remainingqty: data.remainingqty || 0,
            redeemqty: data.redeemqty || 0,
            price: data.price || 0,
            paymentmethod: data.paymentmethod || '',
            expirydate: DateFormat.formatDate(data.expirydate) || '',
        }
    }
}