import SubItemCategory from '@/models/subitemcategory'

export default class SubItemCategoryFactory {
    static createFromJson(json) {
        return new SubItemCategory(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(SubItemCategoryFactory.createFromJson(item))
        })

        return jsonData
    }
}