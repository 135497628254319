<template>
  <div class="list-margin">
    <DataTable
      :value="orders"
      :paginator="true"
      :lazy="true"
      v-model:expandedRows="expandedRows"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="id"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          :currentPageReportTemplate="
        $t('showing') +
        ' ' +
        `{first}` +
        ' ' +
        $t('to') +
        ' ' +
        `{last}` +
        ' ' +
        $t('of') +
        ' ' +
        `{totalRecords}` +
        ' ' +
        $t('results')
      "
      expandedRowIcon="up-icon"
      collapsedRowIcon="down-icon"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">{{$t('ordermanagement')}}</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              :label="$t('add')"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateOrder"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i
                class="search-icon cursor-pointer"
                id="search"
                @click="onSearch"
              />
              <InputText
                v-model="search"
                :placeholder="$t('search')"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> {{$t('loadingrecords')}} </template>
      <template #empty> {{$t('norecords')}} </template>
      <Column field="expand" :expander="true">
        <template #header>
          <span class="p-column-title"></span>
          <button
            class="p-row-toggler p-link header-toggle"
            type="button"
            @click="arrowToggle"
          >
            <span
              :class="{
                'p-row-toggler-icon pi down-icon': !isToggled,
                'p-row-toggler-icon pi up-icon': isToggled,
              }"
            ></span>
          </button>
        </template>
      </Column>
      <Column field="orderno" :header="$t('order')" sortable="true"></Column>
      <Column field="orderdate" :header="$t('orderdate')" sortable="true"></Column>
      <Column field="status" :header="$t('status')" sortable="true">
        <template #body="data">
          <div class="flex" v-if="data.data.status == 'Cancel'">
            <span style="margin-top: -4px"
              ><Badge severity="danger"></Badge
            ></span>
            <span class="cancel-text">Cancel</span>
          </div>
          <div class="flex" v-else>
            <span style="margin-top: -4px"
              ><Badge severity="success"></Badge
            ></span>
            <span class="confirm-text">Confirm</span>
          </div>
        </template>
      </Column>
      <Column
        field="pickuptime"
        :header="$t('pickuptime')"
        sortable="true"
        class="title"
      ></Column>
      <Column
        field="pickupdate"
        :header="$t('pickupdate')"
        sortable="true"
        class="title"
      ></Column>
      <Column
        field="paymentmethod"
        :header="$t('paymentmethod')"
        sortable="true"
      ></Column>
      <Column field="total" :header="$t('totalprice')" sortable="true">
        <template #body="row">
          <div class="flex">
            <span class="set-details">{{ row.data.total.toFixed(1) }}</span>
          </div>
        </template>
      </Column>
      <Column field="action" :header="$t('action')" class="col-width">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              :label="$t('edit')"
              class="edit-button"
              @click="gotoOrderEdit(data.data.id)"
              v-if="data.data.paymentmethod === 'Stripe'"
            />
            <PrimeButton
              :label="$t('delete')"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
              v-if="data.data.paymentmethod === 'Stripe'"
            />
            <PrimeButton
              :label="$t('view')"
              class="view-button"
              @click="goToViewOrder(data.data.id)"
              v-if="data.data.paymentmethod === 'Food Pass'"
            />
            <PrimeButton
              id="cancel"
              :label="$t('cancel')"
              class="cancel-button ml-2"
              @click="onCancel(data.data.id)"
              v-if="
                data.data.status !== 'Cancel' &&
                data.data.paymentmethod === 'Stripe'
              "
            />
          </div>
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="grid">
          <div class="col-8">
            <DataTable :value="slotProps.data.items" class="expand-table">
              <Column field="itemname" :header="$t('itemname')">
                <template #body="row">
                  <div class="flex">
                    <span class="set-details">{{ row.data.itemname }}</span>
                  </div>
                </template>
              </Column>
              <Column field="categoryname" :header="$t('category')">
                <template #body="row">
                  <Chip
                    :label="row.data.categoryname"
                    class="chip-style-for-order-category"
                  />
                </template>
              </Column>
              <Column field="qty" :header="$t('qty')"></Column>
              <Column field="price" :header="$t('price')"></Column>
            </DataTable>
          </div>
          <div class="col-4">
            <Card>
              <template #title>{{$t('ordersummary')}}</template>
              <template #content>
                <div class="flex">
                  <div class="col-6 card-font">{{$t('subtotal')}}</div>
                  <div class="col-6 card-font-digit card-margin-total">
                    ${{ slotProps.data.subtotal.toFixed(1) }}
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="col-5 card-font">{{$t('promotioncode')}}</div>
                  <div class="col-3 card-font-promocode card-margin-promo">
                    {{ slotProps.data.promocode }}
                  </div>
                  <div class="col-2 promo-code-font card-margin-discount">
                    - ${{ slotProps.data.discount.toFixed(1) }}
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="col-6 card-font">{{$t('ordertotal')}}</div>
                  <div class="col-6 card-font-digit-total card-margin-total">
                    ${{ slotProps.data.total.toFixed(1) }}
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </template>
    </DataTable>
    <MainFooter div-class="footer-text" />
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteOrder"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import OrderManagementFactory from "@/factories/ordermanagement";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      orderIdForDelete: "",
      showPopup: false,
      isToggled: false,
      expandedRows: [],
      orders: [],
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    gotoOrderEdit(orderId) {
      this.$router.push({
        name: "OrderManagementEdit",
        params: { id: orderId },
      });
    },
    gotoCreateOrder() {
      this.$router.push({ name: "OrderManagementCreate" });
    },
    expandAll() {
      this.expandedRows = this.orders.filter((x) => x.id);
    },
    collapseAll() {
      this.expandedRows = null;
    },
    arrowToggle() {
      if (this.isToggled) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
      return (this.isToggled = !this.isToggled);
    },
    goToViewOrder(orderId) {
      this.$router.push({
        name: "OrderManagementView",
        params: { id: orderId },
      });
    },
    showDeletePopup(orderId) {
      this.orderIdForDelete = orderId;
      this.showPopup = true;
    },
    onCancel(orderId) {
      APIService.get(`/ordermanagement/status/${orderId}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getOrderList();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    deleteOrder() {
      APIService.delete(`/ordermanagement/delete/${this.orderIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getOrderList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    getOrderList() {
      this.orders = [];
      let encoded = encodeURIComponent(this.search);
      APIService.get(
        `/ordermanagement/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${encoded}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.orders = OrderManagementFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getOrderList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "orderdate") {
        sortField = "orderdate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getOrderList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getOrderList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";

.header-toggle {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.card-margin-total {
  margin-left: 110px;
}

.card-margin-promo {
  margin-left: -19px;
}

.card-margin-discount {
  margin-left: 48px;
}

::v-deep(.expand-table) {
  .p-datatable-table {
    padding-bottom: 8px;
  }

  .p-datatable-wrapper {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
::v-deep(.title.p-sortable-column .p-column-title) {
  text-transform: none !important;
}
.cancel-text {
  height: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #f14747;
  margin-left: 10px;
}
.confirm-text {
  height: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #19a65b;
  margin-left: 10px;
}
.p-badge-dot {
  width: 6px;
  min-width: 0rem;
  height: 6px;
  padding: 0;
}
.chip-style-for-order-category {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 115px;
  height: 40px;
  background: rgba(20, 20, 20, 0.1);
  border-radius: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141414;
}
.set-details {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
