<template>
  <div class="ml-6 mr-6 margin-top mb-5">
    <div class="grid">
          <div v-if="$route.params.id" class="flex order-form-title">{{$t('ordermanagement')}} / {{ $t('edit') }}</div>
        <div v-else class="flex order-form-title">{{$t('ordermanagement')}} / {{ $t('add') }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="order-form-sub-title">{{$t('orderdetail')}}</div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.orderno"
                    :validation="v$.vmodel.orderno"
                    :placeholder="$t('order')"
                    sectionClass="field mt-1"
                    id="order"
                    :label="$t('order')"
                    :disabled="true"
                    class="disable-text-field"
                  />
                </div>
                <div class="col-6">
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    :placeholder="$t('dateformat')"
                    sectionClass="field mt-1"
                    id="orderdate"
                    :label="$t('orderdate')"
                    :minDate="new Date()"
                    v-if="!isEditMode"
                  />
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    :placeholder="$t('orderdate')"
                    sectionClass="field mt-1"
                    :disabled="true"
                    id="orderdate"
                    :label="$t('orderdate')"
                    :minDate="new Date()"
                    v-else
                  />
                </div>
              </div>
              <DropDownRequire
                v-model="vmodel.memberid"
                :validation="v$.vmodel.memberid"
                :placeholder="$t('user')"
                sectionClass="field mt-1"
                id="user"
                :label="$t('user')"
                :options="memberList"
                optionLabel="username"
                optionValue="id"
              />
              <DropDownRequire
                v-model="vmodel.pickuptimeid"
                :validation="v$.vmodel.pickuptimeid"
                :placeholder="$t('pickuptime')"
                sectionClass="field mt-1"
                id="pickuptime"
                :label="$t('pickuptime')"
                :options="pickuptimeList"
                optionLabel="pickuptime"
                optionValue="id"
              />
              <InputDate
                v-model="vmodel.pickupdate"
                :validation="v$.vmodel.pickupdate"
                :placeholder="$t('dateformat')"
                sectionClass="field mt-1"
                id="pickupdate"
                :label="$t('pickupdate')"
                :minDate="new Date()"
              />
              <label for="box" class="form-label field">{{$t('itemlist')}}</label>
              <div id="box" class="box">
                <div
                  class="grid"
                  v-for="(item, index) in vmodel.items"
                  :id="item.id"
                  :key="item.id"
                >
                  <div class="col-5">
                    <DropDownCustom
                      v-model="vmodel.items[index].itemid"
                      :validation="
                        v$.vmodel.items.$each.$response.$errors[index].itemid[0]
                      "
                      :placeholder="$t('itemname')"
                      sectionClass="field"
                      id="item"
                      :label="$t('itemname')"
                      :options="itemList"
                      optionLabel="itemname"
                      optionValue="id"
                      optionGroupLabel="itemname"
                      :optionGroupChildren="['subitemmenu']"
                      class="width-dropdown"
                      :customfield="true"
                      :validated="validated"
                      @update:modelValue="getItemListDetails(index)"
                    />
                  </div>
                  <div class="col-3">
                    <div class="grid">
                      <div class="col-12 flex justify-content-center">
                        <div class="quantity-field">
                          <PrimeButton
                            class="value-button decrease-button"
                            @click="decrement(index)"
                            :class="{
                              'p-invalid':
                                validated &&
                                v$.vmodel.items.$each.$response.$errors[index]
                                  .qty[0],
                            }"
                          >
                            <span class="decrement-icon"></span>
                          </PrimeButton>
                          <InputNumber
                            v-model="vmodel.items[index].qty"
                            class="number"
                            :placeholder="$t('qty')"
                            labelClass="hidden"
                            :class="{
                              'p-invalid':
                                validated &&
                                v$.vmodel.items.$each.$response.$errors[index]
                                  .qty[0],
                            }"
                          />
                          <PrimeButton
                            class="value-button increase-button"
                            @click="increment(index)"
                            :class="{
                              'p-invalid':
                                validated &&
                                v$.vmodel.items.$each.$response.$errors[index]
                                  .qty[0],
                            }"
                          >
                            <span class="increment-icon"></span>
                          </PrimeButton>
                        </div>
                      </div>
                      <div class="col-12">
                        <span
                          v-if="
                            validated &&
                            v$.vmodel.items.$each.$response.$errors[index]
                              .qty[0]
                          "
                          class="p-error text-left mt-2"
                        >
                          Minimum qty allowed is 1
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-3 box-add-sub field"></div> -->
                  <div class="col-3" style="margin-top: 33px">
                    <InputGroupCustom
                      v-model="vmodel.items[index].price"
                      :validation="
                        v$.vmodel.items.$each.$response.$errors[index].price[0]
                      "
                      :placeholder="$t('price')"
                      sectionClass="field"
                      labelClass="hidden"
                      id="price"
                      :label="$t('price')"
                      :customfield="true"
                      :validated="validated"
                    />
                  </div>
                  <div class="col-1 field" style="margin-top: 35px">
                    <PrimeButton
                      icon="bin-icon"
                      class="bin-button"
                      @click="removeItem(index)"
                    />
                  </div>
                </div>
                <div class="grid">
                  <div class="col-4 field">
                    <PrimeButton
                      :label="$t('addnewitem')"
                      icon=""
                      class="add-item-button"
                      @click="repeateAgain()"
                    />
                  </div>
                </div>
              </div>
              <div class="box2">
                <div class="grid">
                  <div class="col-6 field order-form-label">{{$t('subtotal')}}</div>
                  <div
                    class="col-6 field form-label number-form-order margin-for-list"
                  >
                    $ {{ calculateSummary.subTotal.toFixed(1) }}
                  </div>
                </div>
                <div class="grid">
                  <div class="col-6 mt-2">
                    <span class="p-input-icon-right">
                      <i
                        class="apply-icon cursor-pointer"
                        @click="getPromocode"
                        v-if="!applyPromocode"
                      />
                      <i
                        class="close-icon cursor-pointer"
                        @click="clearPromocode"
                        v-else
                      />
                      <InputText
                        v-model="vmodel.promocode"
                        id="promotioncode"
                        :placeholder="$t('promotioncode')"
                      />
                    </span>
                  </div>
                  <div
                    class="col-6 field form-label discount-font number-form-order mt-4 margin-for-list"
                  >
                    -$ {{ discountprice.toFixed(1) }}
                  </div>
                </div>
                <div class="grid mt-1">
                  <div class="col-6 field order-form-label">{{$t('ordertotal')}}</div>
                  <div
                    class="col-6 field form-label total-font number-form-order margin-for-list"
                  >
                    $ {{ calculateSummary.orderTotal.toFixed(1) }}
                  </div>
                </div>
              </div>
              <div v-if="isEditMode == true">
                <InputTextRequire
                  v-model="vmodel.paymentmethod"
                  :validation="v$.vmodel.paymentmethod"
                  sectionClass="field mt-4"
                  id="payment"
                  :label="$t('paymentmethod')"
                  :disabled="true"
                  class="disable-text-field"
                />
              </div>
              <div class="grid mt-4 justify-content-between">
                <PrimeButton
                  :label="$t('add')"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createOrder"
                ></PrimeButton>
                <PrimeButton
                  :label="$t('edit')"
                  class="order-form-button"
                  v-else
                  @click="createOrder"
                ></PrimeButton>
                <PrimeButton
                  :label="$t('cancel')"
                  class="cancel-button-ordermanagement"
                  v-if="isEditMode"
                  @click="gotoOrderList"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import APIService from "@/services/api-service.js";
import { required, minValue, helpers } from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      pickuptimeList: [],
      memberList: [],
      itemList: [],
      addedItemList: [],
      isEditMode: false,
      discounttype: "",
      discountprice: 0,
      applyPromocode: false,
      vmodel: {
        orderno: "",
        orderdate: "",
        memberid: "",
        pickuptimeid: "",
        pickupdate: "",
        promocodeid: "",
        promocode: "",
        subtotal: 0,
        discount: "",
        total: 0,
        paymentmethod: "Stripe",
        items: [
          {
            itemid: "",
            itemname: "",
            categoryid: "",
            categoryname: "",
            qty: 0,
            price: 0,
          },
        ],
      },
      submitted: false,
      validated: false,
    };
  },
  validations() {
    return {
      vmodel: {
        orderno: { required },
        orderdate: { required },
        memberid: { required },
        pickuptimeid: { required },
        pickupdate: { required },
        subtotal: { required },
        discount: { required },
        total: { required },
        paymentmethod: { required },
        items: {
          $each: helpers.forEach({
            itemid: { required },
            itemname: { required },
            categoryid: { required },
            categoryname: { required },
            qty: { required, minValue: minValue(1) },
            price: { required, minValue: minValue(1) },
          }),
        },
      },
    };
  },
  computed: {
    calculateSummary() {
      let subTotal = 0;
      const TotalPrice = this.vmodel.items.map((x) => parseFloat(x.price) * parseFloat(x.qty));
      subTotal = TotalPrice.reduce((partialSum, a) => partialSum + a, 0);

      let orderTotal = 0;
      orderTotal = subTotal - this.discountprice;

      return { subTotal, orderTotal };
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.getOrderById();
    } else {
      this.vmodel.orderno = Math.floor(
        100000000 + Math.random() * 90000000
      ).toString();
    }
    this.getMember();
    this.getPickupTime();
    this.getItem();
  },
  methods: {
    gotoOrderList() {
      this.$router.push({ name: "OrderManagement" });
    },
    increment(index) {
      this.clearPromocode();
      if (typeof this.vmodel.items[index].qty !== "number") {
        this.vmodel.items[index].qty = 0;
      }
      this.vmodel.items[index].qty =
        parseFloat(this.vmodel.items[index].qty) + 1;
      this.calculatePrice(index);
    },
    decrement(index) {
      this.clearPromocode();
      if (typeof this.vmodel.items[index].qty !== "number") {
        this.vmodel.items[index].qty = 0;
      }
      this.vmodel.items[index].qty =
        parseFloat(this.vmodel.items[index].qty) - 1;
      this.calculatePrice(index);
    },
    repeateAgain() {
      const newItem = {
        itemid: "",
        itemname: "",
        categoryid: "",
        categoryname: "",
        qty: 0,
        price: 0,
      };
      this.vmodel.items = [...this.vmodel.items, newItem];
    },
    removeItem(index) {
      this.addedItemList.splice(index, 1);
      this.vmodel.items.splice(index, 1);
    },
    async createOrder() {
      this.vmodel.subtotal = this.calculateSummary.subTotal;
      this.vmodel.discount = this.discountprice;
      this.vmodel.total = this.calculateSummary.orderTotal;

      this.validated = true;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        if (this.vmodel.items.length < 1) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Please select add item in item list.",
            life: 3000,
          });
          return;
        }
        if (this.vmodel.pickupdate < this.vmodel.orderdate) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "pick-up date must be orderdate or above.",
            life: 3000,
          });
          return;
        }
        if (this.vmodel.promocode) {
          if (!this.applyPromocode) {
            this.clearPromocode();
          }
        }
        let payload = {
          orderno: this.vmodel.orderno,
          orderdate: this.vmodel.orderdate,
          memberid: this.vmodel.memberid,
          pickuptimeid: this.vmodel.pickuptimeid,
          pickupdate: this.vmodel.pickupdate,
          promocodeid: this.vmodel.promocodeid,
          promocode: this.vmodel.promocode,
          subtotal: this.vmodel.subtotal,
          discount: this.vmodel.discount,
          total: this.vmodel.total,
          paymentmethod: this.vmodel.paymentmethod,
          items: this.vmodel.items,
          promocodediscount: this.discountprice,
          promocodediscounttype: this.discounttype,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/ordermanagement", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "OrderManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/ordermanagement", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "OrderManagement" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },
    async getOrderById() {
      APIService.getById("/ordermanagement", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.orderno = response.data.data.orderno;
            this.vmodel.orderdate = DateFormat.formatDate(
              response.data.data.orderdate
            );
            const data = response.data.data;
            this.vmodel.memberid = data.memberid;
            this.vmodel.pickuptimeid = data.pickuptimeid;
            this.vmodel.pickupdate = DateFormat.formatDate(
              response.data.data.pickupdate
            );
            this.vmodel.promocodeid = data.promocodeid;
            this.vmodel.promocode = data.promocode;
            this.discountprice = data.discount;
            this.discounttype = data.promocodediscounttype;
            this.vmodel.subtotal = data.subtotal;
            this.vmodel.discount = data.discount;
            this.vmodel.total = data.total;
            this.vmodel.paymentmethod = data.paymentmethod;
            this.vmodel.items = data.items;
            if (
              this.vmodel.promocodeid !== "" ||
              this.vmodel.promocode !== ""
            ) {
              this.applyPromocode = true;
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "OrderManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getMember() {
      APIService.get("/member/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.memberList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPickupTime() {
      APIService.get("/pickupdatetime/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.pickuptimeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getItem() {
      APIService.get("/itemmenu/dropdown/order")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.itemList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPromocode() {
      this.vmodel.subtotal = this.calculateSummary.subTotal;
      if (this.vmodel.subtotal <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select any item.",
          life: 3000,
        });
        return;
      }
      let payload = {
        code: this.vmodel.promocode,
        price: this.vmodel.subtotal,
      };
      APIService.post("/promotioncode/bycode/", payload)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.promocode = response.data.data.code;
            this.discountprice = response.data.data.discountprice;
            this.discounttype = response.data.data.type;
            this.vmodel.promocodeid = response.data.data._id;
            this.applyPromocode = true;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    clearPromocode() {
      this.vmodel.promocode = "";
      this.discountprice = 0;
      this.discounttype = "";
      this.vmodel.promocodeid = "";
      this.applyPromocode = false;
    },
    getItemListDetails(index) {
      let check1 = this.addedItemList.indexOf(this.vmodel.items[index].itemid);
      if (check1 !== -1 && index !== check1) {
        this.vmodel.items[index].itemid = "";
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Item name is already use in Item List.",
          life: 3000,
        });
        return;
      }
      if (this.addedItemList.length > 0) {
        if (this.addedItemList[index]) {
          this.addedItemList[index] = this.vmodel.items[index].itemid;
        } else {
          this.addedItemList.push(this.vmodel.items[index].itemid);
        }
      } else {
        this.addedItemList.push(this.vmodel.items[index].itemid);
      }
      let check = false;
      check = this.itemList
        .filter((x) => x.id === this.vmodel.items[index].itemid)
        .map(() => {
          return true;
        })[0];
      if (check) {
        this.itemList
          .filter((x) => x.id === this.vmodel.items[index].itemid)
          .map((data) => {
            this.vmodel.items[index].itemname = data.itemname;
            this.vmodel.items[index].categoryid = data.cetegoryid;
            this.vmodel.items[index].categoryname = data.cetegory;
          });
      } else {
        let found;
        for (let p of this.itemList) {
          if (p.subitemmenu) {
            for (let i of p.subitemmenu) {
              if (i.id === this.vmodel.items[index].itemid) {
                found = i;
              }
            }
          }
        }
        this.vmodel.items[index].itemname = found.itemname;
        this.vmodel.items[index].categoryid = found.cetegoryid;
        this.vmodel.items[index].categoryname = found.cetegory;
      }
      this.calculatePrice(index);
    },
    calculatePrice(index) {
      if (this.vmodel.items[index].qty > 0) {
        let check = false;
        check = this.itemList
          .filter((x) => x.id === this.vmodel.items[index].itemid)
          .map(() => {
            return true;
          })[0];
        if (check) {
          this.itemList
            .filter((x) => x.id === this.vmodel.items[index].itemid)
            .map((data) => {
              this.vmodel.items[index].price = data.price;
            });
        } else {
          let found;
          for (let p of this.itemList) {
            if (p.subitemmenu) {
              for (let i of p.subitemmenu) {
                if (i.id === this.vmodel.items[index].itemid) {
                  found = i;
                }
              }
            }
          }
          this.vmodel.items[index].price = found.price;
        }
      } else {
        this.vmodel.items[index].price = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: #e5e3e1;
    border: 1px solid #cccac8;
  }
}

::v-deep(.width-dropdown) {
  .p-inputtext {
    width: 186px;
  }
}

.quantity-field {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  width: 150px;
  border-radius: 4px;
  margin-top: 33px;
}

.quantity-field .value-button {
  border: 1px solid #ddd;
  margin: 0px;
  width: 40px;
  height: 52px;
  padding: 13px;
  background: #ffffff;
  outline: none;
  cursor: pointer;
}

.quantity-field .value-button:hover {
  background: #ffffff;
}

.quantity-field .value-button:active {
  background: #ffffff;
}

.quantity-field .decrease-button {
  margin-right: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px 0 0 4px;
  // border-right: 1px solid #e6e3e1 !important;
  width: 50px;
  height: 56px;
}

.quantity-field .increase-button {
  margin-left: -4px;
  border-width: 1px 1px 1px 1px;
  border-radius: 0 4px 4px 0;
  // border-left: 1px solid #e6e3e1 !important;
  // border-left: none;
  width: 50px;
  height: 56px;
}

::v-deep(.quantity-field .number) {
  .p-inputtext {
    display: flex;
    text-align: center;
    border: 1px solid #ddd;
    border-right: none;
    border-left: none;
    // margin: 0px;
    width: 50px;
    height: 56px;
    box-sizing: border-box;
    background: white;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    // padding-top: 15px;
    // padding-right: 5px;
    // padding-left: 1px;
    color: #141414;
    border-radius: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 8px;
    margin-right: 4px;
    margin-left: 4px;
  }
}

::v-deep(.quantity-field .number.p-invalid) {
  .p-inputtext {
    border-color: #dc3545 !important;
  }
}
.qty-style {
  width: 36px;
  margin-top: -21px;
  border-radius: unset;
  border-left: none;
  border-right: none;
  padding-left: 11px;
}

.quantity-field .number::selection {
  background: none;
}

.margin-for-list {
  // margin-left: 387px;
  text-align: right;
}

.item-name-width {
  width: 215px;
}

::v-deep(.p-button.p-invalid) {
  border-color: #dc3545;
}

.discount-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #28aa45;
}

.total-font {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #e77c30;
}
</style>
